import React, { useEffect, useState, useMemo } from "react";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Card from "@material-ui/core/Card";
import Pagination from "@material-ui/lab/Pagination";
import CloseIcon from "@material-ui/icons/Close";
import debounce from "lodash.debounce";
import CustomButton from "../../UI/button/button";
import CustomTextfield from "../../UI/textfield/textfield";
import CustomCheckBox from "../../UI/checkBox/checkBox";
import SearchIcon from "@material-ui/icons/Search";
import SurveyListing from "../../widgets/surveyListing";
import clsx from "clsx";
import { ShowDrawContent } from "../../../components/drawer/showDrawContent";
import FileLayout from "../../widgets/fileLayout/fileLayout";
import SurveyList from "../../widgets/surveyList";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Redirect } from "react-router-dom";
import ListingPageSkeleton from "./../../widgets/skeletons/listingPageSkeleton";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import HelperButton from "./../../widgets/helperButton/helperButton";
import YouTube from "react-youtube";
import SurveyListingTour from "./surveyListingTour";
import fqa from "../../../constants/surveyListing/surveyListingConst";
import surveyListingUseStyles from "./surveyListingStyles";
import addCompany from "../../../images/addCompany.svg";
import TaskFile from "../../../images/TaskFile.svg";
import Accordions from "../../UI/accordions/Accordions";
import ReportlistingPasswordDialog from "../reportListing/reportlistingPasswordDialog";
import { useDispatch } from "react-redux";
import { setAssessmentsPaginationPage } from "../../../actions/surveyListing/surveyListingAction";

function SurveyListingScreen(props) {
  const dispatch = useDispatch();
  const classes = surveyListingUseStyles();

  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [prevTitle, setPrevTitle] = useState("");
  const [drawerOption, setDrawerOption] = useState(0);
  const [showBackArrow, setShowBackArrow] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [currentAssessmentID, setCurrentAssessmentID] = useState("");

  const [assessmentName, setAssessmentName] = useState("");
  const [assessmentAccess, setAssessmentAccess] = useState(false);
  const [assessmentDeleteAccess, setAssessmentDeleteAccess] = useState(false);
  const [assessmentDuplicateAccess, setAssessmentDuplicateAccess] =
    useState(false);
  const [duplicateAssessmentName, setDuplicateAssessmentName] = useState("");

  const [reportSelected, setReportSelected] = useState({
    step_1: true,
    step_2: true,
    step_3: true,
    step_5: false,
    comments: false,
  });

  // pagination state
  const limit = 5;
  const [startingAfter, setStartingAfter] = useState(
    props?.surveyListing?.paginationPage === 0
      ? 0
      : props?.surveyListing?.paginationPage * limit - limit
  );

  const [pages, setPages] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isTourOpen, setIsTourOpen] = useState(false);

  const handleChangeCheckBox = (event) => {
    setReportSelected({
      ...reportSelected,
      [event.target.name]: event.target.checked,
    });
  };

  useEffect(() => {
    setStartingAfter(
      props?.surveyListing?.paginationPage === 0
        ? 0
        : props?.surveyListing?.paginationPage * limit - limit
    );
  }, [props?.surveyListing?.paginationPage]);

  useEffect(() => {
    setPages(Math.ceil(props.surveyListing.assessmentsTotalCount / limit));
  }, [props.surveyListing.assessmentsTotalCount]);

  const AssessmentName = () => {
    return (
      <Dialog open={assessmentAccess} maxWidth="xs" fullWidth>
        <DialogTitle>Change Assessment Name</DialogTitle>
        <DialogContent>
          <CustomTextfield
            label="Assessment Name"
            size="small"
            fullWidth
            color="primary"
            variant="outlined"
            value={assessmentName}
            onChange={(event) => setAssessmentName(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <CustomButton
            size="small"
            color="primary"
            variant="outlined"
            onClick={() => setAssessmentAccess(false)}
          >
            CANCEL
          </CustomButton>
          <CustomButton
            size="small"
            variant="contained"
            color="primary"
            onClick={() => {
              updateAssessmentName(
                props.login.token,
                currentAssessmentID,
                assessmentName,
                surveyListing,
                limit,
                startingAfter,
                false,
                props.login.current_organisation
              );
              setAssessmentAccess(false);
            }}
          >
            UPDATE
          </CustomButton>
        </DialogActions>
      </Dialog>
    );
  };

  const DeleteAssessment = () => {
    return (
      <Dialog open={assessmentDeleteAccess} maxWidth="xs" fullWidth>
        <DialogTitle>Are you sure you want to delete {title}</DialogTitle>

        <DialogActions>
          <CustomButton
            size="small"
            color="primary"
            variant="outlined"
            onClick={() => setAssessmentDeleteAccess(false)}
          >
            CANCEL
          </CustomButton>
          <CustomButton
            size="small"
            variant="contained"
            color="primary"
            onClick={() => {
              deleteAssessments(
                props.login.token,
                currentAssessmentID,
                surveyListing,
                props.login.current_organisation,
                limit,
                startingAfter,
                false
              );
              setAssessmentDeleteAccess(false);
            }}
          >
            DELETE
          </CustomButton>
        </DialogActions>
      </Dialog>
    );
  };

  const DuplicateReport = () => {
    return (
      <Dialog open={assessmentDuplicateAccess} maxWidth="md" fullWidth>
        <DialogTitle className={classes.dialogTitle}>
          <Grid
            container
            justifyContent="center"
            style={{ borderBottom: "1px solid #DADBE6" }}
          >
            <Grid item xs={3}>
              <Typography className={classes.typography}>
                Duplicate Report
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <CustomTextfield
            label="Assessment Name"
            size="small"
            fullWidth
            color="primary"
            variant="outlined"
            value={duplicateAssessmentName}
            onChange={(e) => setDuplicateAssessmentName(e.target.value)}
          />
          <Box display="flex" mt="3px" ml="16px">
            <Typography className={classes.typography1}>Hint :</Typography>
            <Typography className={classes.typography2}>
              Survery EGS Contribution
            </Typography>
          </Box>
          <Box className={classes.mainBox}>
            <Box pl="16px" pt="16px" pb="30px">
              <Typography>Include Data From Selected Report</Typography>
            </Box>
            <Box paddingLeft="24px" pb="79px">
              <Box className={classes.checkboxContainer}>
                <CustomCheckBox
                  className={classes.BasicCheckbox}
                  checked={true}
                  onChange={handleChangeCheckBox}
                  name="checkedA"
                />
                <Typography className={classes.checkboxLabel}>
                  Basic Information
                </Typography>
              </Box>
              <Box className={classes.checkboxContainer}>
                <CustomCheckBox
                  className={classes.BasicCheckbox}
                  checked={true}
                  onChange={handleChangeCheckBox}
                  name="checkedB"
                />
                <Typography className={classes.checkboxLabel}>
                  Materiality Assessment
                </Typography>
              </Box>
              <Box className={classes.checkboxContainer}>
                <CustomCheckBox
                  className={classes.BasicCheckbox}
                  checked={true}
                  onChange={handleChangeCheckBox}
                  name="checkedC"
                />
                <Typography className={classes.checkboxLabel}>
                  ESG Strategy
                </Typography>
              </Box>
              <Box className={classes.checkboxContainer}>
                <CustomCheckBox
                  checked={reportSelected.step_5}
                  onChange={handleChangeCheckBox}
                  className={classes.BasicCheckbox}
                  name="step_5"
                />
                <Typography className={classes.checkboxLabel}>
                  Reporting Data
                </Typography>
              </Box>
              <Box className={classes.checkboxCommentContainer}>
                <CustomCheckBox
                  checked={reportSelected.comments}
                  onChange={handleChangeCheckBox}
                  className={classes.BasicCheckbox}
                  name="comments"
                />
                <Typography className={classes.checkboxLabel}>
                  Comments
                </Typography>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions className={classes.drawerActions}>
          <CustomButton
            size="small"
            color="primary"
            variant="outlined"
            onClick={() => setAssessmentDuplicateAccess(false)}
          >
            Cancel
          </CustomButton>
          <CustomButton
            size="small"
            color="primary"
            variant="contained"
            onClick={() => {
              duplicateReport(
                login.token,
                currentAssessmentID,
                reportSelected,
                surveyListing,
                duplicateAssessmentName,
                limit,
                startingAfter,
                false,
                props.login.current_organisation
              );
              setAssessmentDuplicateAccess(false);
            }}
          >
            Create Report
          </CustomButton>
        </DialogActions>
      </Dialog>
    );
  };

  const onReady = (event) => {
    event.target.pauseVideo();
  };

  const opts = {
    height: "200px",
    width: "380px",
    playerVars: {
      autoplay: 1,
    },
  };

  const handleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleTourOpen = () => {
    setIsDrawerOpen(false);
    setIsTourOpen(true);
  };

  const helperDrawer = () => {
    return (
      <>
        <Drawer anchor="right" open={isDrawerOpen} onClose={handleDrawer}>
          <div style={{ width: 430 }}>
            <Grid container justifyContent="space-between">
              <Grid item md={6} xs={6}>
                <Typography className={classes.typography3}>
                  Help Center
                </Typography>
              </Grid>
              <Grid item md={2} xs={3}>
                <IconButton onClick={handleDrawer}>
                  <CloseIcon className={classes.closeIcon} />
                </IconButton>
              </Grid>
            </Grid>
            <div style={{ padding: 24 }}>
              <Card elevation={3} className={classes.card}>
                <YouTube
                  videoId={"_pgfXIEeK30"}
                  opts={opts}
                  onReady={onReady}
                />
              </Card>
              <CustomButton
                onClick={handleTourOpen}
                className={classes.costumButton}
              >
                Click here
              </CustomButton>
              <span>for quick tour</span>

              <Typography className={classes.typography4}>
                Follow up questions
              </Typography>
              <div>
                {fqa.map(({ title, desc }) => (
                  <Accordions title={title} content={desc} />
                ))}
              </div>
            </div>
          </div>
        </Drawer>
      </>
    );
  };

  const {
    login,
    viewAssessments,
    surveyListing,
    setKeyword,
    setCurrentStep,
    setActiveStep,
    getOrganizations,
    updateAssessmentName,
    deleteAssessments,
    duplicateReport,
    getHelpFlow,
    updateHelpFlow,
    changePasswordValidation,
    setLogin,
    getAllCountries,
  } = props;

  const onClickHandler = (title) => {
    setOpen(true);
    setTitle(title);
  };

  const updateDrawerOption = (option) => {
    setDrawerOption(option);
  };
  const showDrawer = (option) => {
    setShowBackArrow(1);
  };
  const setPreviousTitle = (title) => {
    setPrevTitle(title);
  };
  const onSearchHandler = (e) => {
    setSearchText(e.target.value);
    debouncedFetchData(e.target.value);
  };

  // pagination function
  const handleChange = (event, value) => {
    setStartingAfter(value * limit - limit);
    dispatch(setAssessmentsPaginationPage(value));
    return;
  };

  useEffect(() => {
    return () => {
      setKeyword("");
    };
  }, []);

  useEffect(() => {
    props.login.current_organisation &&
      viewAssessments(
        props.login.token,
        limit,
        startingAfter,
        surveyListing,
        props.login.current_organisation,
        surveyListing.keyword,
        false
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.login.current_organisation, startingAfter]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // props.login.current_organisation && getOrganizations(props.login.token,window.location.hostname);
    props.login.current_organisation &&
      getOrganizations(props.login.token, window.location.hostname);
    props.login.current_organisation && getAllCountries(login.token);
  }, [props.login.token, props.login.current_organisation]); // eslint-disable-line react-hooks/exhaustive-deps

  const debouncedFetchData = useMemo(
    () =>
      debounce((query) => {
        setKeyword(query);
        viewAssessments(
          props.login.token,
          limit,
          startingAfter,
          surveyListing,
          props.login.current_organisation,
          query,
          false
        );
      }, 1200),
    [searchText] // eslint-disable-line react-hooks/exhaustive-deps
  );

  //getting completed steps array on first load

  const completedSteps = JSON.parse(localStorage.getItem("completed_steps"));

  useEffect(() => {
    if (Array.isArray(completedSteps)) {
      if (completedSteps.length !== 11) {
        getHelpFlow(login.token);
      }
    }
  }, []);

  //checking whether the user completed the step or not
  const SURVERY_LISTING = "survey_listing";
  useEffect(() => {
    if (Array.isArray(completedSteps) && completedSteps) {
      if (
        !completedSteps.includes(SURVERY_LISTING) &&
        surveyListing.assessments.length > 0
      ) {
        setIsTourOpen(true);
      } else {
        setIsTourOpen(false);
      }
    }
  }, [surveyListing.assessments]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleNameChange = () => {
    setAssessmentAccess(true);
    setOpen(false);
  };
  const handleDeleteAssessment = () => {
    setAssessmentDeleteAccess(true);
    setOpen(false);
    setOpen(false);
  };
  const handleDuplicateAssessment = () => {
    setAssessmentDuplicateAccess(true);
    setOpen(false);
    setOpen(false);
  };

  const ChangeNameFromDrawer = ({ handleNameChange }) => {
    return (
      <div
        className={classes.tabdiv}
        onClick={() => handleNameChange()}
        style={{ cursor: "pointer", paddingLeft: 25 }}
      >
        <Typography variant="h6" className={classes.drawerTypography}>
          Change Name
        </Typography>
        <ArrowForwardIcon />
      </div>
    );
  };

  const DeleteAssessmentFromDrawer = ({ handleDeleteAssessment }) => {
    return (
      <div
        className={classes.tabdiv}
        onClick={() => handleDeleteAssessment()}
        style={{ cursor: "pointer", paddingLeft: 25 }}
      >
        <Typography variant="h6" className={classes.drawerTypography}>
          Delete Assessment
        </Typography>
        <ArrowForwardIcon />
      </div>
    );
  };

  const DuplicateAssessmentFromDrawer = ({ handleDuplicateAssessment }) => {
    return (
      <div
        className={classes.tabdiv}
        onClick={() => handleDuplicateAssessment()}
        style={{ cursor: "pointer", paddingLeft: 25 }}
      >
        <Typography variant="h6" className={classes.drawerTypography}>
          Duplicate Report
        </Typography>
        <ArrowForwardIcon />
      </div>
    );
  };

  if (surveyListing.redirect_value)
    return (
      <Redirect
        to={{
          pathname: "/sustainability_report",
          state: { assessment_id: surveyListing.assessment_id },
        }}
      />
    );

  return (
    <div>
      <HelperButton setIsDrawerOpen={setIsDrawerOpen} />
      {isDrawerOpen && helperDrawer()}
      {assessmentAccess && AssessmentName()}
      {assessmentDeleteAccess && DeleteAssessment()}
      {assessmentDuplicateAccess && DuplicateReport()}
      {!surveyListing.changePasswordDialog && (
        <SurveyListingTour
          updateHelpFlow={updateHelpFlow}
          token={login.token}
          isTourOpen={isTourOpen}
          setIsTourOpen={setIsTourOpen}
          setOpen={setOpen}
        />
      )}

      <Grid
        container
        alignItems="center"
        justifyContent="flex-start"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Grid item className={classes.margin_padding}>
          <img src={TaskFile} alt="" />
        </Grid>
        <Grid item xs={12} md={2} className={classes.margin_padding1}>
          <Typography variant="h6" className={classes.esgTypo}>
            ESG Assessments
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={5}>
          <CustomTextfield
            size="small"
            type="text"
            label="Search"
            color="primary"
            variant="outlined"
            fullWidth={true}
            value={searchText}
            onChange={(e) => onSearchHandler(e)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon className={classes.SearchIcon} size="small" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={4} md={1} className={classes.AddnewGrid}>
          <CustomButton
            color="primary"
            variant="outlined"
            onClick={() => {
              props.createAssessment(
                props.login.token,
                props.login.current_organisation
              );
            }}
          >
            Add new
          </CustomButton>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Pagination
            variant="outlined"
            color="primary"
            count={pages}
            siblingCount={0}
            onChange={handleChange}
            page={
              props?.surveyListing?.paginationPage === 0
                ? 1
                : props?.surveyListing?.paginationPage
            }
          />
        </Grid>
      </Grid>
      {surveyListing.changePasswordDialog && (
        <ReportlistingPasswordDialog
          changePasswordValidation={changePasswordValidation}
          setLogin={setLogin}
          surveyListing={surveyListing}
          login={login}
        />
      )}
      {!surveyListing.assessmentStatus && <ListingPageSkeleton />}
      {!surveyListing.assessments.length > 0 &&
        surveyListing.assessmentStatus && (
          <Grid container className={classes.mainGrid}>
            <div className={classes.emptyDiv}></div>
            <Grid item xs={12}>
              <img src={addCompany} alt="" />
            </Grid>
            <Grid item xs={12} className={classes.subGrid}>
              <CustomButton
                color="primary"
                variant="outlined"
                style={{ backgroundColor: "white" }}
                onClick={() => {
                  props.createAssessment(
                    props.login.token,
                    props.login.current_organisation
                  );
                }}
              >
                Add new
              </CustomButton>
            </Grid>
            <Grid item xs={12} className={classes.subGrid}>
              <Typography variant="body1">
                Start By Clicking The Add New To <br /> Create A New Materiality
                Listing
              </Typography>
            </Grid>
          </Grid>
        )}
      {surveyListing.assessments.length > 0 && (
        <Grid container item justifyContent="center">
          <SurveyListing
            open={open}
            setOpen={setOpen}
            onClickHandler={onClickHandler}
            setPreviousTitle={setPreviousTitle}
            setAssessmentName={setAssessmentName}
            assessments={surveyListing.assessments}
            viewActivityLog={props.viewActivityLog}
            token={props.login.token}
            setCurrentStep={setCurrentStep}
            setCurrentAssessmentID={setCurrentAssessmentID}
            viewAssessments={viewAssessments}
            surveyListing={surveyListing}
            startingAfter={startingAfter}
            currentOrganisation={login.current_organisation}
            limit={limit}
          />
        </Grid>
      )}
      <Drawer
        variant="persistent"
        anchor="right"
        open={open}
        classes={{ paper: classes.drawerPaper }}
      >
        <div data-tut="reactour__action_drawer">
          <div className={classes.drawerHeader}>
            <div className={classes.drawerDiv}>
              {showBackArrow === 1 && (
                <ArrowBackIcon
                  onClick={() => {
                    setShowBackArrow(0);
                    updateDrawerOption(0);
                    setTitle(prevTitle);
                  }}
                />
              )}
              <Typography
                variant="h6"
                className={classes.draweHeadTypo}
                style={{ marginLeft: drawerOption === 0 ? 22 : 2 }}
              >
                {title}
              </Typography>
              <IconButton
                onClick={() => {
                  setOpen(false);
                  setShowBackArrow(0);
                  updateDrawerOption(0);
                }}
                className={classes.closeIcon1}
              >
                <Icon>close</Icon>
              </IconButton>
            </div>
          </div>
          <div className="scroll" style={{ height: "70vh" }}>
            {drawerOption === 0 && (
              <>
                <ShowDrawContent
                  onClickHandler={onClickHandler}
                  updateDrawerOption={updateDrawerOption}
                  showDrawer={showDrawer}
                />
                <ChangeNameFromDrawer handleNameChange={handleNameChange} />
                <DeleteAssessmentFromDrawer
                  handleDeleteAssessment={handleDeleteAssessment}
                />
                <DuplicateAssessmentFromDrawer
                  handleDuplicateAssessment={handleDuplicateAssessment}
                />
              </>
            )}

            {drawerOption === 1 && (
              <>
                <FileLayout
                  onClickHandler={onClickHandler}
                  title={"Assessment Approval"}
                  files={["Assessment Approval"]}
                  currentAssessmentID={currentAssessmentID}
                  setActiveStep={setActiveStep}
                />
                <FileLayout
                  onClickHandler={onClickHandler}
                  title={"Sustainability Report"}
                  files={["Sustainability Report"]}
                  currentAssessmentID={currentAssessmentID}
                  setActiveStep={setActiveStep}
                />
              </>
            )}
            {drawerOption === 2 && (
              <div>
                <SurveyList
                  title="Basic Information"
                  activityLog={surveyListing.activityLog.filter(
                    (item) => item.step_no === 1
                  )}
                  currentStep={surveyListing.currentStep}
                />
                <SurveyList
                  title="Materiality Assessment"
                  activityLog={surveyListing.activityLog.filter(
                    (item) => item.step_no === 2
                  )}
                  currentStep={surveyListing.currentStep}
                />
                <SurveyList
                  title="ESG Strategy"
                  activityLog={surveyListing.activityLog.filter(
                    (item) => item.step_no === 3
                  )}
                  currentStep={surveyListing.currentStep}
                />
                <SurveyList
                  title="Assessment Approval"
                  activityLog={surveyListing.activityLog.filter(
                    (item) => item.step_no === 4
                  )}
                  currentStep={surveyListing.currentStep}
                />
                <SurveyList
                  title="Field Data"
                  activityLog={surveyListing.activityLog.filter(
                    (item) => item.step_no === 5
                  )}
                  currentStep={surveyListing.currentStep}
                />
                <SurveyList
                  title="Sustainability Report"
                  activityLog={surveyListing.activityLog.filter(
                    (item) => item.step_no === 6
                  )}
                  currentStep={surveyListing.currentStep}
                />
              </div>
            )}
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default SurveyListingScreen;
