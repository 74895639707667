import { makeStyles, Paper, withStyles } from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Chip from "@material-ui/core/Chip";

import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import LinearProgress from "@material-ui/core/LinearProgress";
import CustomButton from "../../UI/button/button";
import React, { useEffect, useState } from "react";
import SyncIcon from "@material-ui/icons/Sync";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import {
  Business,
  Environmental,
  Governance,
  Human,
  Social,
} from "../../../utils/MaterialityAssessmentUtils/materialityAssessmentUtils";
import clsx from "clsx";
import MaturityMatrixCharts from "./MaturityMatrixCharts";
import score0Large from "../../../images/score0Large.jpg";
import beginerLarge from "../../../images/beginerLarge.png";
import intermediateLarge from "../../../images/intermideateLarge.png";
import expertLarge from "../../../images/expertLarge.png";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import chatToolTip from "../../../images/chatToolTip.png";
import CustomTextfield from "../../UI/textfield/textfield";
import Autocomplete from "@material-ui/lab/Autocomplete";

const MaturityMatrixRightStyles = makeStyles((theme) => ({
  cardContainer: {
    backgroundColor: "#FFFFFF",
    borderRadius: 5,
    // height:'100%'
  },
  mainHeadder: {
    display: "flex",
    justifyContent: "space-between",
    margin: "8px 0px 8px 0px",
  },
  Typography: {
    fontWeight: 600,
    fontSize: "16px",
    marginLeft: "10px",
  },
  Typography2: {
    fontWeight: 600,
    fontSize: "16px",
    marginLeft: "4px",
  },
  iconCont: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  cursorPointer: {
    cursor: "pointer",
  },
  headerIcon: {
    marginRight: "20px",
  },
  Table: {
    outline: "0.5px solid lightGrey",
    borderRadius: "5px",
    boxShadow: "1px 1px 1px",
    height: "100%",
  },
  TableContainer: {
    padding: "3px",
  },
  TableCell: {
    padding: "5px",
    border: "none",
  },
  hedaderCell: { color: "grey", fontSize: "x-small" },
  topicCell: { padding: "4px 4px" },
  lableCell: {
    // width: '193px',
    // height: '20px',
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    // lineHeight: "20px",
  },
  scoreCell: {
    textAlign: "right",
    // width: '32px',
    // height: '28px',
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "22px",
    lineHeight: "28px",
  },
  SuggestionBtn: {
    textTransform: "none",
    textAlign: "center",
    width: "100%",
    fontFamily: "Poppins",
    margin: "5% 0px 5px 1px",
    padding: "5px",
  },
  contributorGrid: {
    padding: "0px 15px 0px 10px",
  },
  contributorsBtn: {
    backgroundColor: "white",
    color: "green",
    border: "0.5px solid green",
    borderRadius: "5px",
    textTransform: "none",
    textAlign: "center",
    width: "100%",
    fontFamily: "Poppins",
    margin: "5% 0px 5px 1px",
  },
  topicStyle: {
    width: "40px",
    height: "40px",
    marginTop: "5px",
    position: "relative",
    top: "5px",
  },
  levelImg: {
    width: "140px",
    height: "135px",
  },
  ".MuiLinearProgress-root": {
    color: "green",
  },
  LinearProgress: {
    // color:'success.main',
    margin: "-5px 25px 25px 25px",
  },
  scoreTypo: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "xx-large",
  },
  maxScoreTypo: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
  },
  sharesurvey: {
    color: "green",
    fontWeight: 600,
  },
  describe: {
    color: "grey",
    fontSize: "x-small",
    marginTop: "11px",
  },
  scoreImage: {
    width: "200px",
    height: "190px",
  },
  mainGrid: {
    textAlign: "center",
  },
  discribe: {
    fontSize: "small",
  },
  slider: {
    ".MuiSlider-root": {
      color: "#20b2aa",
    },
    ".MuiSlider-root:hover": {
      color: "#2e8b57",
    },
  },
  levelTypo: {
    // marginLeft: "5%",
    textAlign: "center",
  },
  progressIndicator1: {
    position: "relative",
    left: "33%",
    top: "12px",
    backgroundColor: "rgb(0 128 0)",
    zIndex: 1,
  },
  progressIndicator2: {
    position: "relative",
    left: "66.7%",
    top: "12px",
    backgroundColor: "rgb(0 128 0)",
    zIndex: 1,
  },
  [theme.breakpoints.down("lg")]: {
    SuggestionBtn: {
      margin: "5% 0px 5px 1px",
    },
    discribe: {
      fontSize: "small",
      textWrap: "no-wrap",
      wordWrap: "no-wrap",
    },
  },
  [theme.breakpoints.down("md")]: {
    SuggestionBtn: {
      margin: "5% 0px 5px 1px",
      padding: "5px",
    },
    contributorsBtn: {
      margin: "5% 0px 5px 1px",
      paddingLeft: "5px",
    },
    discribe: {
      textAlign: "left",
      fontSize: "small",
      textWrap: "no-wrap",
      wordWrap: "no-wrap",
    },
    sharesurvey: {
      fontWeight: 500,
    },
  },
}));

const Progress = withStyles({
  root: {
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "green",
    },
  },
})(LinearProgress);

// const IndicatorTool = withStyles((theme) => ({
//   tooltip: {
//     backgroundColor: "green",
//     color: "white",
//     fontSize: "small",
//     // zIndex: 0 + '!important',
//   },
//   popper: {
//     //  zIndex: 0 + '!important',
//   },
//   arrow: {
//     color: "green",
//   },
// }))(Tooltip);

// const SliderInd = withStyles((theme) => ({
//   root: {
//     color: 'green',
//     fontSize: 'small',
//     height: '8px',
//     '& .MuiSlider-rail': {
//       height: '8px',
//     },
//     '& .MuiSlider-track': {
//       height: '8px',
//     },
//   },
// }))(Slider);

function MaturityMatrixRight(props) {
  const classes = MaturityMatrixRightStyles();
  const {
    setInfo,
    mainTabno,
    login,
    assessment_id,
    setSuggestions,
    setExternalSuggestions,
    currentMaturityData,
    setContributor,
    setDetails,
    basicInfo,
    viewAssignedMembers,
    getInternalMaturityMatrix,
    getExternalUsers,
  } = props;
  const { shareDialogOpen, setShareDialogOpen, shareDialog } =
    props.shareAssessmentUtils;
  const [scoreList, setScoreList] = useState([
    {
      component: <Environmental className={classes.topicStyle} />,
      lable: "Environment",
      score: currentMaturityData.category_matrix_value["Environment"]
        ? currentMaturityData.category_matrix_value["Environment"]
        : 0,
    },
    {
      component: <Social className={classes.topicStyle} />,
      lable: "Social Capital ",
      score: currentMaturityData.category_matrix_value["Social Capital"]
        ? currentMaturityData.category_matrix_value["Social Capital"]
        : 0,
    },
    {
      component: <Business className={classes.topicStyle} />,
      lable: "Business Model & Innovation ",
      score: currentMaturityData.category_matrix_value[
        "Business Model & Innovation"
      ]
        ? currentMaturityData.category_matrix_value[
            "Business Model & Innovation"
          ]
        : 0,
    },
    {
      component: <Governance className={classes.topicStyle} />,
      lable: "Leadership & Governance ",
      score: currentMaturityData.category_matrix_value[
        "Leadership & Governance"
      ]
        ? currentMaturityData.category_matrix_value["Leadership & Governance"]
        : 0,
    },
    {
      component: <Human className={classes.topicStyle} />,
      lable: "Human Capital ",
      score: currentMaturityData.category_matrix_value["Human Capital"]
        ? currentMaturityData.category_matrix_value["Human Capital"]
        : 0,
    },
  ]);
  const [score, setScore] = useState(currentMaturityData.total_value / 1);

  const handleContributors = () => {
    viewAssignedMembers(login.token, assessment_id, 1);
    setContributor(true);
  };

  const handleViewSuggestion = () => {
    mainTabno === 0 && setSuggestions(true);
    mainTabno === 1 && setExternalSuggestions(true);
  };

  const handleRefresh = () => {
    mainTabno === 0 && getInternalMaturityMatrix(login.token, assessment_id);
    mainTabno === 1 && getExternalUsers(login.token, assessment_id);
  };

  return (
    <div className={classes.mainContainer}>
      {shareDialogOpen && shareDialog()}
      <Grid
        container
        className={classes.mainHeadder}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item container alignItems="center" xs>
          {mainTabno === 1 ? (
            <>
              <ArrowBackIcon
                className={classes.cursorPointer}
                onClick={() => setDetails(false)}
              />
              <Typography className={classes.Typography}>
                {`${currentMaturityData.organisation_name || ""} Maturity`}
              </Typography>
            </>
          ) : (
            <Typography className={classes.Typography2}>
              {`${basicInfo.companyName} Maturity`}
            </Typography>
          )}
        </Grid>
        <Grid item className={classes.iconCont}>
          <Tooltip
            className={classes.cursorPointer}
            title="Refresh"
            placement="top"
            arrow
          >
            <SyncIcon
              variant="outlined"
              onClick={handleRefresh}
              className={classes.headerIcon}
              color="primary"
            />
          </Tooltip>
          <Tooltip
            className={classes.cursorPointer}
            title="Info"
            placement="top"
            arrow
          >
            <InfoOutlinedIcon
              onClick={() => setInfo(true)}
              className={classes.headerIcon}
              color="primary"
              variant="outlined"
            />
          </Tooltip>
          <Tooltip
            className={classes.cursorPointer}
            title="Share"
            placement="top"
            arrow
          >
            <ShareOutlinedIcon
              onClick={() => setShareDialogOpen(true)}
              className={classes.headerIcon}
              color="primary"
              variant="outlined"
            />
          </Tooltip>
        </Grid>
      </Grid>
      <div className="scroll">
        <Grid container>
          <Grid item md={6} lg={6} sm={12} className={classes.mainGrid}>
            {score === 0 && (
              <img
                src={score0Large}
                className={classes.scoreImage}
                alt="score0Large"
              />
            )}
            {score > 0 && score < 33.3 && (
              <img src={beginerLarge} alt="beginerLarge" />
            )}
            {score >= 33.3 && score < 66.6 && (
              <img src={intermediateLarge} alt="intermediateLarge" />
            )}
            {score >= 66.6 && <img src={expertLarge} alt="expertLarge" />}
            <Typography className={classes.maxScoreTypo}>
              <span className={classes.scoreTypo}>{score}</span> / 100{" "}
            </Typography>
            {score === 0 && (
              <Typography className={classes.sharesurvey}>
                Share Survey To identify your Score
              </Typography>
            )}
            {score > 0 && score < 33.3 && (
              <Typography className={classes.sharesurvey}>
                Share Survey To identify your Score
              </Typography>
            )}
            {score >= 33.3 && score < 66.6 && (
              <Typography className={classes.sharesurvey}>
                Intermediate, Good progress.
              </Typography>
            )}
            {score >= 66.6 && (
              <Typography className={classes.sharesurvey}>
                Advanced, Great !
              </Typography>
            )}
            <Typography className={classes.discribe}>
              Your Survey Data is Generated using the contributions from your
              stakeholders
            </Typography>
          </Grid>
          <Grid item md={6} lg={6} sm={12}>
            <TableContainer className={classes.TableContainer}>
              <Table className={classes.Table}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      className={clsx(classes.TableCell, classes.hedaderCell)}
                    >
                      Topic
                    </TableCell>
                    <TableCell
                      className={clsx(classes.TableCell, classes.hedaderCell)}
                    >
                      Lable
                    </TableCell>
                    <TableCell
                      className={clsx(classes.TableCell, classes.hedaderCell)}
                    >
                      Score
                    </TableCell>
                  </TableRow>
                </TableHead>
                {scoreList.map((topic) => {
                  return (
                    <TableBody key={topic.lable}>
                      <TableRow>
                        <TableCell
                          className={clsx(classes.TableCell, classes.topicCell)}
                        >
                          {topic.component}
                        </TableCell>
                        <TableCell
                          className={clsx(classes.TableCell, classes.lableCell)}
                        >
                          {topic.lable}
                        </TableCell>
                        <TableCell
                          className={clsx(classes.TableCell, classes.scoreCell)}
                        >
                          {topic.score}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })}
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            className={classes.contributorGrid}
            item
            lg={6}
            md={6}
            sm={6}
            xs={6}
          >
            {mainTabno !== 1 && (
              <CustomButton
                variant="contained"
                size="small"
                className={classes.contributorsBtn}
                onClick={handleContributors}
              >
                <PeopleOutlineIcon /> View Contributors
              </CustomButton>
            )}
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <CustomButton
              variant="contained"
              color="secondary"
              size="small"
              className={classes.SuggestionBtn}
              onClick={handleViewSuggestion}
            >
              View Suggestions
            </CustomButton>
          </Grid>
        </Grid>
        <Grid>
          {/* <div style={{ marginRight: '10%' }}>
            <IndicatorTool
              style={{
                // zIndex: -1500 + '!important',
                position: 'relative',
                left: `${  score <= 4
                  ? 0
                  : score <= 30
                  ? score - 4.5
                  : score <= 70
                  ? score - 7
                  : score <= 95
                  ? score - 10
                  : 85}%`,
              }}
              open={true}
              placement="top-start"
              title="You're Here"
              arrow
            >
              <div></div>
            </IndicatorTool>
          </div> */}
          <div
            style={{
              height: 46,
              width: 112,
              display: "flex",
              alignItems: "center",
              position: "relative",
              left: `${
                score <= 6
                  ? 0
                  : score <= 30
                  ? score - 5.5
                  : score <= 60
                  ? score - 6
                  : score <= 70
                  ? score - 7.2
                  : score <= 90
                  ? score - 7.8
                  : score <= 95
                  ? score - 11
                  : 85
              }%`,
              marginTop: 10,
            }}
          >
            <img
              style={{ height: "85%", width: "85%" }}
              src={chatToolTip}
              alt=""
            />
          </div>
          <div className={classes.LinearProgress}>
            <span className={classes.progressIndicator1}>'</span>
            <span className={classes.progressIndicator2}>'</span>
            <Progress
              // style={{ zIndex: 0 + '!important',}}
              color="primary"
              variant="determinate"
              value={score}
            />
          </div>
        </Grid>
        <Grid
          item
          md={12}
          sm={12}
          container
          style={{ justifyContent: "space-evenly" }}
        >
          <Grid item>
            <Typography className={classes.levelTypo}>Beginner</Typography>
            <img
              className={classes.levelImg}
              src={beginerLarge}
              alt="beginerLarge"
            />
          </Grid>
          <Grid item>
            <Typography className={classes.levelTypo}>Intermediate</Typography>
            <img
              className={classes.levelImg}
              src={intermediateLarge}
              alt="intermediateLarge"
            />
          </Grid>
          <Grid item>
            <Typography className={classes.levelTypo}>Expert</Typography>
            <img
              className={classes.levelImg}
              src={expertLarge}
              alt="expertLarge"
            />
          </Grid>
        </Grid>
        <Grid>
          <Grid>
            <MaturityMatrixCharts
              currentMaturityData={currentMaturityData}
              {...props}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default MaturityMatrixRight;
