import React, { useState, useEffect } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import CustomTextfield from "../../UI/textfield/textfield";
import CustomButton from "./../../UI/button/button";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ClearIcon from "@material-ui/icons/Clear";
import { DateRange } from "react-date-range";
import { addDays } from "date-fns";
import Avatar from "@material-ui/core/Avatar";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import moment from "moment";
import FrameworksDialog from "../basicInformationRight/frameworksDialog";
import basicInfoRightStyles from "./basicInfoTabRightStyles";
import ReactFlagsSelect from "react-flags-select";

const BasicAssessmentDetails = (props) => {
  const {
    basicInfo,
    login,
    setAssessmentName,
    setYearOfAssessment,
    setNewYearOfAssessment,
    setSustainibilityTeamMessage,
    setEsgGoal,
    setAboutReport,
    setDocument,
    getFrameworkDetails,
    setFrameworkId,
    setFrameworkDefaultFilter,
    setReportingRegion,
    viewFilteredFrameworks,
    set_snack_bar,
    getSectorsByFramework,
  } = props;

  const classes = basicInfoRightStyles();
  const [isDatePickerDialogOpen, setIsDatePickerDialogOpen] = useState(false);
  const [frameworkOpen, setFrameworkOpen] = useState(false);

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [yearRange, setyearRange] = useState([]);
  const [startYear, setStartYear] = useState("");
  const [endYear, setEndYear] = useState("");

  useEffect(() => {
    getFrameworkDetails(login.token);
  }, []);

  useEffect(() => {
    basicInfo?.newYearOfAssessment &&
      setStartYear(basicInfo?.newYearOfAssessment?.split("-")[0]);
    basicInfo?.newYearOfAssessment &&
      setEndYear(basicInfo?.newYearOfAssessment?.split("-")[1]);
  }, [basicInfo?.newYearOfAssessment]);

  const handleFramworkClose = (id, defaultFilter) => {
    setFrameworkOpen(false);
    setFrameworkId(id);
    setFrameworkDefaultFilter(defaultFilter);
    getSectorsByFramework(login.token, id);
  };

  const handleOnselect = (code) => {
    basicInfo.allCountries.length > 0 &&
      basicInfo.allCountries.map((countries) => {
        if (countries.country_code === code) {
          viewFilteredFrameworks(
            login.token,
            countries._id,
            login.current_organisation
          );
        }
      });

    setReportingRegion(code);
  };

  useEffect(() => {
    const data = [];
    if (!basicInfo?.newYearOfAssessment) {
      for (let i = 0; i < 9; i++) {
        data.push(currentYear - 5 + i);
      }
      setyearRange(data);
    } else if (basicInfo?.newYearOfAssessment) {
      for (let i = 0; i < 9; i++) {
        data.push(basicInfo?.newYearOfAssessment.split("-")[0] - 3 + i);
      }
      setyearRange(data);
    }
  }, [isDatePickerDialogOpen]);

  const handleYearBack = () => {
    const data = [];
    for (let i = 0; i < 9; i++) {
      data.push(yearRange[0] - 9 + i);
    }
    setyearRange(data);
  };
  const handleYearForward = () => {
    const data = [];
    for (let i = 0; i < 9; i++) {
      data.push(yearRange[0] + 9 + i);
    }
    setyearRange(data);
  };
  const handleYearClick = (year) => {
    if (startYear && endYear) {
      setStartYear(year);
      setEndYear();
    } else if (!startYear) {
      setStartYear(year);
    } else if (year < startYear) {
      setStartYear(year);
    } else if (year !== startYear) {
      setEndYear(year);
    } else {
      set_snack_bar(true, "Select a Different Year");
    }
  };

  const range = (year) => {
    if (startYear === year) return true;
    if (endYear === year) return true;
    if (year <= endYear && year >= startYear) return true;
    else return false;
  };

  const datePickerDialog = () => {
    return (
      <>
        <Dialog maxWidth="lg" open={isDatePickerDialogOpen}>
          <DialogContent>
            <Typography>
              <DateRange
                onChange={(item) => {
                  // setState([item.selection]);
                  // setYearOfAssessment([item.selection]);
                }}
                ranges={state}
                months={2}
                direction="horizontal"
              />
            </Typography>
          </DialogContent>
          <DialogActions style={{ display: "flex", justifyContent: "right" }}>
            <CustomButton
              variant="outlined"
              color="primary"
              onClick={() => {
                setIsDatePickerDialogOpen(false);
              }}
            >
              Close
            </CustomButton>
            <CustomButton
              variant="contained"
              color="primary"
              style={{ padding: "6px 20px" }}
              onClick={() => {
                setIsDatePickerDialogOpen(false);
              }}
            >
              Set Period
            </CustomButton>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const yearPickerDialog = () => {
    return (
      <>
        <Dialog maxWidth="lg" open={isDatePickerDialogOpen}>
          <DialogContent style={{ width: "100%" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  fontWeight: 600,
                  marginLeft: "15px",
                  marginBottom: "15px",
                }}
              >{`${startYear || ""}-${endYear || ""}`}</Typography>
              <Typography
                onClick={handleYearBack}
                style={{
                  fontSize: "22px",
                  cursor: "pointer",
                  fontWeight: 600,
                  marginLeft: "15px",
                  marginBottom: "15px",
                }}
              >{`<`}</Typography>
              <Typography
                onClick={handleYearForward}
                style={{
                  fontSize: "22px",
                  cursor: "pointer",
                  fontWeight: 600,
                  marginLeft: "15px",
                  marginBottom: "15px",
                }}
              >{`>`}</Typography>
              <Typography
                onClick={() => {
                  setStartYear("");
                  setEndYear("");
                }}
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  fontWeight: 600,
                  marginLeft: "15px",
                  marginBottom: "15px",
                  float: "right",
                }}
              >{`Clear`}</Typography>
            </div>
            <div>
              <Grid
                container
                item
                xs={12}
                md={12}
                gap={1}
                spacing={2}
                style={{
                  padding: "2% 1% 4% 1%",
                  // // marginLeft: '2%',
                }}
              >
                {yearRange.map((year, idx) => {
                  return (
                    <Grid
                      key={idx}
                      item
                      xs={4}
                      md={4}
                      align="center"
                      style={{
                        // margin:'6px 8px',
                        // border: "1px solid blue",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        onClick={() => handleYearClick(year)}
                        style={{
                          fontWeight: 500,
                          padding: "8px 5px",
                          border: range(year) ? "1px solid #3374B9" : "none",
                          borderRadius: "5px",
                          backgroundColor: range(year) ? "#F0F6FD" : "inherit",
                        }}
                      >
                        {year}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </DialogContent>
          <DialogActions style={{ display: "flex", justifyContent: "center" }}>
            <CustomButton
              // variant="outlined"
              color="primary"
              style={{ textTransform: "none" }}
              onClick={() => {
                setNewYearOfAssessment(basicInfo.newYearOfAssessment);
                setYearOfAssessment(basicInfo.yearOfAssessment);
                basicInfo.newYearOfAssessment &&
                  setStartYear(basicInfo.newYearOfAssessment.split("-")[0]);
                basicInfo.newYearOfAssessment &&
                  setEndYear(basicInfo.newYearOfAssessment.split("-")[1]);
                setIsDatePickerDialogOpen(false);
              }}
            >
              Cancel
            </CustomButton>
            <CustomButton
              variant="contained"
              color="primary"
              style={{ padding: "6px 20px", textTransform: "none" }}
              disabled={!startYear || !endYear}
              onClick={() => {
                setNewYearOfAssessment(`${startYear}-${endYear}`);
                setYearOfAssessment(`${startYear}-${endYear}`);
                setIsDatePickerDialogOpen(false);
              }}
            >
              Set Period
            </CustomButton>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  return (
    <>
      {yearPickerDialog()}
      <Card
        variant="elevation"
        id="drawer-container"
        elevation={1}
        className={classes.cardContainer}
        data-tut="reactour__basicLeft"
      >
        <CardContent className={classes.cardContent}>
          <Typography className={classes.cardTypography}>
            Basic Assessment Details
          </Typography>

          <div className="scroll" style={{ marginTop: 18, height: "63vh" }}>
            <Grid container>
              <Grid item xs={12} md={9}>
                <Box my={2}>
                  <CustomTextfield
                    variant="outlined"
                    fullWidth
                    size="small"
                    label="Assessment Name"
                    name="assessmentName"
                    value={basicInfo.assessmentName}
                    onChange={(e) => setAssessmentName(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={9}>
                <Box
                  my={2}
                  onClick={() => setIsDatePickerDialogOpen(true)}
                  className={classes.box}
                >
                  {basicInfo?.newYearOfAssessment ? (
                    <Typography variant="body1" name="yearOfAssesment">
                      {`${basicInfo?.newYearOfAssessment.split("-")[0]} - ${
                        basicInfo?.newYearOfAssessment.split("-")[1]
                      }`}
                    </Typography>
                  ) : (
                    <Typography variant="body1" name="yearOfAssesment">
                      Year of Assessment
                    </Typography>
                  )}
                  {/* <Typography variant="body1" name="yearOfAssesment">
                    {basicInfo.yearOfAssessment[0].startDate !== "" &&
                    basicInfo.yearOfAssessment[0].endDate !== ""
                      ? `${moment(
                          new Date(basicInfo.yearOfAssessment[0].startDate)
                        ).format("DD MMM yy")} - ${moment(
                          new Date(basicInfo.yearOfAssessment[0].endDate)
                        ).format("DD MMM yy")}`
                      : "Year Of Assessment"}
                  </Typography> */}
                </Box>
              </Grid>
              <Grid item xs={12} md={9}>
                <ReactFlagsSelect
                  selected={basicInfo.reportingRegion}
                  onSelect={(code) => handleOnselect(code)}
                  searchable
                  optionsSize={18}
                  selectedSize={18}
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <Box my={2}>
                  <Card variant="outlined" className={classes.card}>
                    <CardContent
                      className={classes.cardContent1}
                      onClick={() => {
                        //  if(basicInfo.filteredFrameworks) {
                        (Object.keys(basicInfo.filteredFrameworks).length > 0 &&
                          basicInfo.reportingRegion === undefined) ||
                        !basicInfo.reportingRegion
                          ? set_snack_bar(true, "Select A Country First")
                          : setFrameworkOpen(true);
                      }}
                    >
                      {basicInfo.frameworks.length > 0 &&
                        basicInfo.frameworks !== null &&
                        basicInfo.frameworks !== undefined &&
                        basicInfo.frameworks !== "" &&
                        basicInfo.frameworks.map(
                          (work, index) =>
                            work._id === basicInfo.frameworkId && (
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                key={index}
                                name="frmSelector"
                              >
                                {work.image === undefined ? (
                                  ""
                                ) : (
                                  <>
                                    {work.image === "" ||
                                    work.image === null ? (
                                      <Avatar className={classes.avatar}>
                                        F
                                      </Avatar>
                                    ) : (
                                      <Avatar
                                        src={work.image[0]}
                                        className={classes.avatar}
                                      />
                                    )}
                                  </>
                                )}
                                <Typography
                                  variant="subtitle2"
                                  gutterBottom
                                  style={{ marginTop: 6 }}
                                >
                                  {work.framework_name}
                                </Typography>
                              </Box>
                            )
                        )}
                      <div className={classes.div}>
                        <ExpandMoreIcon />
                      </div>
                    </CardContent>
                  </Card>
                  <FrameworksDialog
                    open={frameworkOpen}
                    setOpen={setFrameworkOpen}
                    framework={basicInfo.filteredFrameworks}
                    onClose={handleFramworkClose}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={9}>
                <Box my={2}>
                  <CustomTextfield
                    name="SusTeamMsg"
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={3}
                    size="small"
                    label="Sustainabilty Team Message"
                    value={basicInfo.sustainibilityTeamMessage}
                    onChange={(e) =>
                      setSustainibilityTeamMessage(e.target.value)
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={9}>
                <Box my={2}>
                  <CustomTextfield
                    name="esgGoal"
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={3}
                    size="small"
                    label="ESG Goals Overall"
                    value={basicInfo.esgGoal}
                    onChange={(e) => setEsgGoal(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={9}>
                <Box my={2}>
                  <CustomTextfield
                    name="abtReport"
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={3}
                    size="small"
                    label="About Report"
                    value={basicInfo.aboutReport}
                    onChange={(e) => setAboutReport(e.target.value)}
                  />
                </Box>
              </Grid>
              {false && (
                <Grid item xs={12} md={9}>
                  <Box my={2}>
                    {basicInfo.document === null ? (
                      <>
                        <input
                          id="contained-button-document"
                          type="file"
                          onChange={(event) =>
                            setDocument(event.target.files[0])
                          }
                          style={{ display: "none" }}
                        />
                        <label htmlFor="contained-button-document">
                          <Box
                            display="flex"
                            component="span"
                            alignItems="center"
                          >
                            <AttachFileIcon color="disabled" />
                            <Box
                              mx={2}
                              style={{
                                borderBottom: "2px solid gray",
                                cursor: "pointer",
                              }}
                              width="100%"
                            >
                              <Typography
                                variant="caption"
                                color="textSecondary"
                              >
                                Attach Additional Policy Documents
                              </Typography>
                            </Box>
                          </Box>
                        </label>
                      </>
                    ) : (
                      <>
                        <Box display="flex" alignItems="center">
                          <AttachFileIcon color="disabled" />
                          <Box
                            mx={2}
                            style={{ borderBottom: "2px solid #3374B9" }}
                            width="100%"
                          >
                            <Typography variant="caption" color="primary">
                              {basicInfo.document.name}
                            </Typography>
                          </Box>
                          <ClearIcon
                            color="primary"
                            style={{ cursor: "pointer" }}
                            onClick={() => setDocument(null)}
                          />
                        </Box>
                      </>
                    )}
                  </Box>
                </Grid>
              )}
            </Grid>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default BasicAssessmentDetails;
