import React, { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import {
  createTheme,
  makeStyles,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import CustomTextfield from "../../UI/textfield/textfield";
import CustomButton from "../../UI/button/button";
import CustomCheckBox from "../../UI/checkBox/checkBox";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MuiAccordion from "@material-ui/core/Accordion";
import RemoveCircleOutlinedIcon from "@material-ui/icons/RemoveCircleOutlined";
import MaturityMatrixRight from "../maturityMatrix/MaturityMatrixRight";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import clsx from "clsx";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import {
  Business,
  Environmental,
  Governance,
  Human,
  Social,
} from "../../../utils/MaterialityAssessmentUtils/materialityAssessmentUtils";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import {
  AppBar,
  Avatar,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  Tooltip,
} from "@material-ui/core";
import SyncIcon from "@material-ui/icons/Sync";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import infoPentagon from "../../../images/infoPentagon.jpg";
import EnvironmentArrow from "../../../images/EnvironmentArrow.jpg";
import BusinessArrow from "../../../images/BusinessArrow.jpg";
import GovernanceArrow from "../../../images/GovernanceArrow.jpg";
import HumanArrow from "../../../images/HumanArrow.jpg";
import SocialArrow from "../../../images/SocialArrow.jpg";
import ExpandMore from "@material-ui/icons/ExpandMore";
import RadioButton from "../../UI/radioButton/radioButton";
import ProfilePlaceholder from "../../../images/ProfilePlaceholder.png";
import { stakeHolderCategoryList } from "../../../constants/materialityConst/materialityConst";
import BasicInfoTabRight from "./basicInfoTabRight";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    backgroundColor: "#FFFFFF",
    marginBottom: "15px",
    borderRadius: 5,
    height: "100%",
  },
  headerText: {
    fontWeight: "600",
  },
  cardTypography: {
    fontWeight: "600",
    marginLeft: "10px",
  },
  arrow: {
    marginLeft: "5px",
  },
  logoSelecter: {
    border: "2px dashed #DADBE6",
    width: 120,
    height: 120,
    borderRadius: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      "& $addIcon": {
        color: "#3374B9",
      },
    },
  },
  addIcon: {
    color: "#DADBE6",
  },
  companyLogo: {
    border: "2px solid #DADBE6",
    width: 120,
    height: 120,
    borderRadius: 5,
    zIndex: 1,
    "& img": {
      width: "100%",
      height: "100%",
    },
    position: "relative",
  },
  ClearImage: {
    position: "absolute",
    right: 0,
    top: 0,
    padding: 2,
  },
  addSectorContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
  },
  addBranchContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
  },
  blueText: {
    color: "#3374B9",
    fontWeight: "600",
    fontSize: 14,
  },
  selectedSectorTitle: {
    color: "#77787B",
    fontSize: 14,
  },
  removeChipIcon: {
    display: "none",
  },
  surveyStatOrange: {
    color: "#9FA1AF",
    fontWeight: "400",
    fontSize: 16,
    lineHeight: "24px",
    paddingLeft: 5,
    borderLeft: "3px solid orange",
  },
  surveyStatGreen: {
    color: "#9FA1AF",
    fontWeight: "400",
    fontSize: 16,
    paddingLeft: 5,
    borderLeft: "3px solid green",
  },
  tableHeaderText: {
    fontSize: "small",
    color: "grey",
  },
  topicCardBoxShadow: {
    boxShadow: "0px 0px 24px rgba(51, 116, 185, 0.16)",
    "&:first-child": {
      marginTop: "32px",
    },
    marginLeft: "16px",
    marginRight: "16px",
    marginBottom: "24px",
  },
  mainTopicContainer: {
    display: "flex",
    alignItems: "center",
    padding: 10,
  },
  environmentBorder: {
    backgroundColor: "#DAF1E4",
    height: 4,
    width: "100%",
  },
  socialBorder: {
    backgroundColor: "#EAD6F1",
    height: 4,
    width: "100%",
  },
  governanceBorder: {
    backgroundColor: "#D6E3F1",
    height: 4,
    width: "100%",
  },
  businessBorder: {
    backgroundColor: "#F1D6D6",
    height: 4,
    width: "100%",
  },
  humanBorder: {
    backgroundColor: "#F6F3DA",
    height: 4,
    width: "100%",
  },
  topicContainer: {
    display: "flex",
    alignItems: "center",
    padding: 15,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#D6E3F173",
    },
  },
  subTopicContainer: {
    alignItems: "center",
    padding: "0px 25px 0px 50px",
    cursor: "pointer",
  },
  topicText: {
    fontWeight: "600",
    marginLeft: 10,
    fontSize: "16px",
    lineHeight: "24px",
  },
  AppBar: { boxShadow: "none" },
  tab: {
    fontSize: 12,
    minWidth: "120px",
  },
  tabLabel: {
    textTransform: "capitalize",
    fontSize: 14,
    fontFamily: "Poppins",
  },
  detailsBtn: {
    textTransform: "none",
    color: "blue",
  },
  mainHeadder: {
    display: "flex",
    justifyContent: "space-between",
    margin: "10px 0px 10px 0px",
  },
  Typography: {
    fontWeight: "600",
  },
  iconCont: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  headerIcon: {
    marginRight: "20px",
  },
  pentagon: {
    width: "100%",
  },
  humanArrow: {
    paddingBottom: "25px",
  },
  summary: {
    "&:hover": {
      backgroundColor: "#D6E3F173",
    },
  },
  details: {
    display: "flex",
    margin: "7px 5px",
    padding: "0px",
  },
  radio: {
    padding: "0px",
  },
  suggestionText: {
    marginLeft: "7px",
    padding: "5px 0px",
  },
  accDetails: {
    margin: "0px",
    padding: "0px",
  },
  tr: {
    marginTop: "20px",
    color: "#1C4066",
    "&:hover": {
      backgroundColor: "#EBF1F8",
    },
  },
  color: {
    color: "#1C4066",
  },
  noSuggestionText: {
    textAlign: "center",
    marginTop: "10px",
    padding: "20px",
  },
  cursorPointer: {
    cursor: "pointer",
  },
  arrowDiv: { marginTop: "16px" },
  mainGrid2: { borderBottom: "1px solid #DADBE6", paddingBottom: 5 },
  Typography3: {
    paddingBottom: 5,
    borderBottom: "3px solid green",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
  },
  grid8: { width: "100%" },
  sectorChip: {
    justifyContent: "flex-start",
    color: "#77787B",
    backgroundColor: "#F5F6F8",
    borderRadius: "5%",
    border: "0px",
    margin: "4px 8px 4px 0px",
    "&&:hover": {
      "& $removeChipIcon": {
        display: "inline",
      },
    },
    "&&:focus": {
      color: "#77787B",
      backgroundColor: "#F5F6F8",
    },
  },
  Typography5: {
    fontWeight: "bold",
  },
  CustomTextfield: { marginTop: 5, color: "#9FA1AF" },
  shareContentText: {
    color: "#9FA1AF",
  },
  grid7: { marginTop: 20 },
  grid3: { display: "flex", justifyContent: "flex-end" },
  CustomButton3: { paddingLeft: 20, paddingRight: 20 },
}));

const theme = createTheme({
  palette: {
    secondary: {
      main: "#47BB76",
    },
    // [theme.breakpoints.down('lg')]: {
    //   width: '83%',
    //   marginTop: '8px'
    // }
  },
  topicname: {
    fontFamily: ["poppins", "sans-serif"].join(","),
  },
  selectTopicText: {
    color: "#9FA1AF",
    fontSize: 13,
    padding: "10px 0px",
    fontFamily: ["poppins", "sans-serif"].join(","),
  },
  containerSelectTopicText: {
    marginBottom: 7,
  },
  emptyTopicBar: {
    width: "100%",
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#DADBE655",
    borderRadius: 10,
  },
  relavanceAvatar: {
    width: 35,
    height: 35,
    color: "#000",
    backgroundColor: "#fff",
    border: "1px solid #DADBE6",
    fontSize: 16,
    lineHeight: "24px",
    fontFamily: "Poppins",
  },
  stakholderTitle: {
    fontWeight: "600",
    marginLeft: 15,
    fontFamily: "Poppins",
    fontSize: 16,
    lineHeight: "24px",
    textTransform: "capitalize",
  },
});
const Accordion = withStyles({
  root: {
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

export default function BasicInformationRisght(props) {
  const classes = useStyles();
  const {
    basicInfo,
    assessment_id,
    login,
    maturityMatrix,
    getExternalUsers,
    editStakeholder,
    getInternalMaturityMatrix,
    resetMaturityMatrix,
    assignMembers,
  } = props;

  const [isSectorDialogOpen, setIsSectorDialogOpen] = useState(false);
  const [expanded, setExpanded] = React.useState(false);

  const [sectors, setsectors] = useState([]);

  useEffect(() => {
    if (basicInfo.sectors && basicInfo.sectors.length > 0) {
      let masterSectors = [...basicInfo.masterSectors];
      masterSectors.map(
        (masterSec, masterSecIdx) =>
          basicInfo.sectors.some((sec) => sec.sector === masterSec._id) &&
          masterSec.industry_data.map((masterInd, masterIndIdx) =>
            basicInfo.sectors.map((basicSec) =>
              basicSec.industry.map(
                (basicInd) =>
                  masterInd._id === basicInd &&
                  (masterSectors[masterSecIdx].industry_data[
                    masterIndIdx
                  ].isSelected = true)
              )
            )
          )
      );
      setsectors(masterSectors);
    } else {
      setsectors(basicInfo.masterSectors);
    }
  }, [basicInfo.masterSectors, basicInfo.sector]); // eslint-disable-line react-hooks/exhaustive-deps

  // resetting maturity matrix data while unmounting
  useEffect(() => {
    return () => {
      resetMaturityMatrix();
    };
  }, []);

  const handleCloseSectorDialog = () => {
    setIsSectorDialogOpen(false);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleIndustryChange = (industryIndex, sectorIndex) => {
    var sectorL = [...sectors];
    sectorL[sectorIndex].industry_data[industryIndex].isSelected =
      !sectorL[sectorIndex].industry_data[industryIndex].isSelected;
    setsectors(sectorL);
  };

  const sectorIndustry = () => {
    return (
      <Dialog
        maxWidth="lg"
        open={isSectorDialogOpen}
        onClose={handleCloseSectorDialog}
      >
        <DialogTitle>
          <Grid
            container
            justifyContent="center"
            style={{ borderBottom: "1px solid #DADBE6" }}
          >
            <Grid item xs={4}>
              <Typography
                style={{
                  paddingBottom: 5,
                  borderBottom: "3px solid green",
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bold",
                }}
              >
                Sector & Industry Selection
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{ width: 900 }}>
            <Grid item xs={6}>
              <Typography className={classes.blueText}>
                Select Sectors
              </Typography>
              <div style={{ marginTop: 15 }}>
                {sectors.map((sector, index) => (
                  <Accordion
                    key={index}
                    expanded={expanded === `panel1${index}`}
                    onChange={handleChange(`panel1${index}`)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography className={classes.heading}>
                        {sector.Sector}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      {sector.industry_data.map((industry, index2) => (
                        <div
                          key={index2}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => handleIndustryChange(index2, index)}
                        >
                          <CustomCheckBox checked={industry.isSelected} />
                          <Typography>{industry.Industry}</Typography>
                        </div>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.blueText}>Selected</Typography>
              <Card
                variant="outlined"
                style={{ minHeight: 350, width: "95%", marginTop: 15 }}
              >
                <CardContent>
                  {sectors.map((sector, index) => (
                    <div key={index} style={{ marginBottom: 8 }}>
                      {sector.industry_data.some((inds) => inds.isSelected) && (
                        <>
                          <Typography className={classes.selectedSectorTitle}>
                            {sector.Sector}
                          </Typography>
                          {sector.industry_data.map(
                            (industry, index2) =>
                              industry.isSelected && (
                                <Chip
                                  key={index2}
                                  className={classes.sectorChip}
                                  onDelete={() =>
                                    handleIndustryChange(index2, index)
                                  }
                                  deleteIcon={
                                    <RemoveCircleOutlinedIcon
                                      className={classes.removeChipIcon}
                                    />
                                  }
                                  label={industry.Industry}
                                />
                              )
                          )}
                        </>
                      )}
                    </div>
                  ))}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CustomButton
            onClick={handleCloseSectorDialog}
            variant="outlined"
            color="primary"
          >
            Cancel
          </CustomButton>
          <CustomButton
            onClick={() => {
              setIsSectorDialogOpen(false);
            }}
            variant="contained"
            color="primary"
          >
            Complete
          </CustomButton>
        </DialogActions>
      </Dialog>
    );
  };

  const Instruction = () => {
    return (
      <>
        {/* <Card
          variant="elevation"
          id="drawer-container"
          elevation={1}
          // className={classes.cardContainer}
          data-tut="reactour__basicRight"
        >
          <CardContent style={{ padding: 10 }}> */}
        <Grid container className={classes.arrowDiv}>
          <ArrowBackIcon
            className={classes.cursorPointer}
            onClick={() => setInfo(false)}
          />
          <Typography className={classes.cardTypography}>
            Maturity Assessment
          </Typography>
        </Grid>

        <div className="scroll">
          <Accordion defaultExpanded={true}>
            <AccordionSummary expandIcon={<ExpandMore />} open>
              <Typography>Survey Bifurcation (5) </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid>
                <Grid>
                  <Typography>
                    All the Survey Questions are bifurcated into 5 Sustainablity
                    Dimension as below
                  </Typography>
                </Grid>
                <Grid>
                  <img
                    className={classes.pentagon}
                    src={infoPentagon}
                    alt="infoPentagon"
                  ></img>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography>Genral issue Categories (26) </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid>
                <Grid>
                  <Typography>
                    Each of these Sustainability Dimension are further dimension
                    are further dimensioned into 26 categories
                  </Typography>
                </Grid>
                <Grid>
                  <img
                    className={classes.pentagon}
                    src={BusinessArrow}
                    alt="BusinessArrow"
                  />
                  <img
                    className={classes.pentagon}
                    src={EnvironmentArrow}
                    alt="EnvironmentArrow"
                  />
                  <img
                    className={classes.pentagon}
                    src={SocialArrow}
                    alt="SocialArrow"
                  />
                  <img
                    className={classes.pentagon}
                    src={GovernanceArrow}
                    alt="GovernanceArrow"
                  />
                  <img
                    className={clsx(classes.pentagon, classes.HumanArrow)}
                    src={HumanArrow}
                    alt="HumanArrow"
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>
        {/* </CardContent>
        </Card> */}
      </>
    );
  };
  const [stakeHolder, setStakeHolder] = useState({
    name: "",
    email: "",
    category: "",
    type: "",
    designation: "",
    organization: "",
    organisation_id: "",
  });
  const [isStakeholderDialogOpen, setIsStakeholderDialogOpen] = useState(false);
  const handleUserProfileClick = (stakeholder) => {
    setStakeHolder({
      ...stakeHolder,
      userId: stakeholder.user_data._id,
      stakeholderId: stakeholder.stakeholder_id,
      name: stakeholder.user_data.name,
      email: stakeholder.user_data.email,
      category: stakeholder.category,
      type: stakeholder.type,
      designation: stakeholder.designation,
      organization: stakeholder.organisation,
    });
  };

  const handleEditStakeholder = () => {
    editStakeholder(
      login.token,
      assessment_id,
      stakeHolder.userId,
      stakeHolder.designation,
      stakeHolder.organization,
      stakeHolder.category,
      stakeHolder.type,
      1,
      login.current_organisation
    );

    setIsStakeholderDialogOpen(false);
  };

  const editStakeHolderDialog = () => {
    return (
      <Dialog
        maxWidth="md"
        open={isStakeholderDialogOpen}
        // onClose={setIsStakeholderDialogOpen(false)}
      >
        <DialogTitle>
          <Grid container justifyContent="center" className={classes.grid1}>
            <Grid item xs={2}>
              <Typography className={classes.Typography1}>
                Edit Stakeholder
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Box m={2}>
                <CustomTextfield
                  name="stkName"
                  label="Name"
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled
                  value={stakeHolder.name}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box m={2}>
                <CustomTextfield
                  name="stkRole"
                  label="Role"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={stakeHolder.designation}
                  onChange={(e) =>
                    setStakeHolder({
                      ...stakeHolder,
                      designation: e.target.value,
                    })
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box m={2}>
                <CustomTextfield
                  name="stkEmail"
                  label="Email"
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled
                  value={stakeHolder.email}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box m={2}>
                <CustomTextfield
                  name="stkOrg"
                  label="Organization"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={stakeHolder.organization}
                  onChange={(e) =>
                    setStakeHolder({
                      ...stakeHolder,
                      organization: e.target.value,
                    })
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box m={2}>
                <CustomTextfield
                  name="stkCategory"
                  label="Category"
                  variant="outlined"
                  size="small"
                  fullWidth
                  select
                  value={stakeHolder.category}
                  onChange={(e) => {
                    setStakeHolder({
                      ...stakeHolder,
                      category: e.target.value,
                    });
                  }}
                >
                  {stakeHolderCategoryList.map((cat, index) => (
                    <MenuItem key={index} value={cat}>
                      {cat}
                    </MenuItem>
                  ))}
                </CustomTextfield>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box m={2}>
                <CustomTextfield
                  name="stkPosition"
                  label="Type"
                  variant="outlined"
                  size="small"
                  fullWidth
                  select
                  value={stakeHolder.type}
                  onChange={(e) =>
                    setStakeHolder({ ...stakeHolder, type: e.target.value })
                  }
                >
                  {["Internal", "External"].map((cat) => (
                    <MenuItem key={cat} value={cat}>
                      {cat}
                    </MenuItem>
                  ))}
                </CustomTextfield>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CustomButton
            onClick={() => setIsStakeholderDialogOpen(false)}
            variant="outlined"
            size="small"
            color="primary"
            name="cancelBtn"
          >
            Cancel
          </CustomButton>
          <CustomButton
            name="updateStkBtn"
            className={classes.CustomButton1}
            onClick={handleEditStakeholder}
            variant="contained"
            size="small"
            color="primary"
            disabled={
              stakeHolder.email === "" ||
              stakeHolder.category === "" ||
              stakeHolder.type === "" ||
              stakeHolder.designation === "" ||
              stakeHolder.organization === ""
            }
          >
            Update Stakeholder
          </CustomButton>
        </DialogActions>
      </Dialog>
    );
  };
  const Contributors = () => {
    return (
      <div>
        {/*  <Card
          variant="elevation"
          id="drawer-container"
          elevation={1}
          className={classes.cardContainer}
          data-tut="reactour__basicRight"
        >
         <CardContent style={{ padding: 0 }}> */}
        <Grid container style={{ marginTop: "16px" }}>
          <ArrowBackIcon
            className={classes.cursorPointer}
            onClick={() => setContributor(false)}
          />
          <Typography className={classes.cardTypography}>
            Contributors
          </Typography>
        </Grid>

        <div className="scroll">
          <TableContainer>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell component={"td"}>
                    <Typography className={classes.tableHeaderText}>
                      Name/Email
                    </Typography>
                  </TableCell>
                  <TableCell component={"td"}>
                    <Typography className={classes.tableHeaderText}>
                      Survey Status
                    </Typography>
                  </TableCell>
                  <TableCell component={"td"}>
                    <Typography className={classes.tableHeaderText}>
                      Position
                    </Typography>
                  </TableCell>
                  <TableCell component={"td"}>
                    {/* <Typography className={classes.tableHeaderText}>
                          Category
                        </Typography> */}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {maturityMatrix.maturityMatrixReviewer.map(
                  (contributor, index) => (
                    <TableRow component={"tr"} key={index}>
                      <TableCell component="td" scope="row">
                        <Typography
                          style={{ paddingTop: "4px" }}
                          className={classes.boldText}
                        >
                          {contributor.user_data.name}{" "}
                        </Typography>
                        <Typography style={{ fontSize: "small" }}>
                          {contributor.user_data.email}
                        </Typography>
                      </TableCell>
                      <TableCell component={"td"}>
                        <Typography
                          className={
                            contributor.survey_status === "Sent"
                              ? classes.surveyStatOrange
                              : classes.surveyStatGreen
                          }
                        >
                          {contributor.survey_status}
                        </Typography>
                      </TableCell>
                      <TableCell component={"td"}>
                        <Typography>{contributor.designation}</Typography>
                      </TableCell>

                      <TableCell component={"td"} className={classes.TableCell}>
                        <IconButton
                          name="editStkHolder"
                          onClick={() => {
                            setIsStakeholderDialogOpen(true);
                            handleUserProfileClick(contributor);
                          }}
                        >
                          <CreateOutlinedIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {/* </CardContent>
        </Card> */}
      </div>
    );
  };

  const [currentMaturityData, setCurrentMaturityMatrixData] = useState([]);

  const TopicCard = ({ topic }) => {
    const { topic_name, sub_topics } = topic;

    return (
      <div
        style={
          {
            // marginLeft: '16px',
            // marginRight: '16px',
            // marginBottom: '24px'
            // padding: 10,
          }
        }
      >
        <Card variant="elevation" className={classes.topicCardBoxShadow}>
          <CardContent style={{ padding: 0 }}>
            <div className={classes.mainTopicContainer}>
              {topic_name === "Social Capital" && <Social />}
              {topic_name === "Environment" && <Environmental />}
              {topic_name === "Leadership & Governance" && <Governance />}
              {topic_name === "Human Capital" && <Human />}
              {topic_name === "Business Model & Innovation" && <Business />}
              <Typography className={classes.mainTopicText}>
                {topic.topic_name}
              </Typography>
            </div>
            {topic_name === "Social Capital" && (
              <div className={classes.socialBorder}></div>
            )}
            {topic_name === "Environment" && (
              <div className={classes.environmentBorder}></div>
            )}
            {topic_name === "Leadership & Governance" && (
              <div className={classes.governanceBorder}></div>
            )}
            {topic_name === "Human Capital" && (
              <div className={classes.humanBorder}></div>
            )}
            {topic_name === "Business Model & Innovation" && (
              <div className={classes.businessBorder}></div>
            )}

            {sub_topics.map((subTopic, i) => (
              <React.Fragment key={i}>
                {" "}
                <Accordion>
                  <AccordionSummary className={classes.summary}>
                    <div
                      style={
                        false
                          ? { backgroundColor: "rgb(189 213 239 / 52%)" }
                          : null
                      }
                      className={classes.topicContainer}
                      // onClick={(e) => {
                      //   handleToggle(e, i);
                      // }}
                    >
                      <AddBoxOutlinedIcon />
                      <Typography className={classes.topicText}>
                        {subTopic.topic_name}
                      </Typography>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accDetails}>
                    <div className={classes.subTopicContainer}>
                      {subTopic.suggestions && (
                        <div className={classes.details}>
                          <RadioButton
                            className={classes.radio}
                            size="small"
                            checked={true}
                          />
                          <Typography className={classes.suggestionText}>
                            {subTopic.suggestions}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </React.Fragment>
            ))}
          </CardContent>
        </Card>
      </div>
    );
  };

  const Suggestions = () => {
    return (
      <>
        {/* <Card
          variant="elevation"
          id="drawer-container"
          elevation={1}
          className={classes.cardContainer}
          data-tut="reactour__basicRight"
        >
          <CardContent style={{ padding: 10 }}> */}
        <Grid container style={{ marginTop: "16px" }}>
          <ArrowBackIcon
            className={classes.cursorPointer}
            onClick={() => {
              mainTabno === 0 && setSuggestions(false);
              mainTabno === 1 && setExternalSuggestions(false);
            }}
          />
          <Typography className={classes.cardTypography}>
            Suggestions
          </Typography>
        </Grid>

        <div className="scroll">
          {maturityMatrix.internalData &&
            suggestions &&
            mainTabno === 0 &&
            maturityMatrix.internalData.suggestion_category.map(
              (topic, index) => {
                return <TopicCard key={index} topic={topic} />;
              }
            )}

          {maturityMatrix.externalData &&
            mainTabno === 1 &&
            externalSuggestions &&
            currentMaturityData.suggestion_category.map((topic, index) => {
              return <TopicCard key={index} topic={topic} />;
            })}

          {currentMaturityData.suggestion_category.length === 0 && (
            <Typography className={classes.noSuggestionText}>
              You have No Suggestions Yet
            </Typography>
          )}
        </div>
        {/* </CardContent>
        </Card> */}
      </>
    );
  };
  // share assessment dialog and it's states
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [stakeholderContent, setStakeholderContent] = useState({
    subject: "",
    content: `At ${
      login.selectedOrganization && login.selectedOrganization.company_name
    } we are proud to have you as one of our key stakeholder. As part of our sustainability reporting process, We are reaching out to gather your views as to the key sustainability topics we should report on.`,
  });
  const [emails, setEmails] = useState([]);

  const stakeHolderContentChange = (e) => {
    const { name, value, defaultValue } = e.target;
    setStakeholderContent((prev) => {
      return { ...prev, [name]: value };
    });
    if (stakeholderContent.content === "") {
      setStakeholderContent((prev) => {
        return { ...prev, [name]: defaultValue };
      });
    }
  };

  const handleShareAssessment = () => {
    assignMembers(
      login.token,
      emails,
      1,
      assessment_id,
      login.current_organisation,
      stakeholderContent.subject,
      stakeholderContent.content
    );
    setEmails([]);
  };
  const shareDialog = () => {
    return (
      <Dialog open={shareDialogOpen} fullWidth maxWidth="sm">
        <DialogTitle>
          <Grid container justifyContent="center" className={classes.mainGrid2}>
            <Grid item xs={4}>
              <Typography className={classes.Typography3}>
                Share Assessment
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={1}
            direction="column"
            alignItems="flex-start"
            // style={{}}
          >
            <Grid item md={12} className={classes.grid8}>
              <Paper elevation={0} className={classes.Paper}>
                <Autocomplete
                  size="small"
                  multiple
                  value={emails}
                  options={maturityMatrix.notAssignedUsers.map(
                    (user) => user.email
                  )}
                  renderTags={(value, getTagProps) => {
                    return value.map((option, index) => (
                      <Chip
                        key={index}
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ));
                  }}
                  onChange={(event, newValue) => {
                    setEmails(newValue);
                  }}
                  renderInput={(params) => (
                    <>
                      <CustomTextfield
                        {...params}
                        size="small"
                        variant="outlined"
                        fullWidth
                        label="Search By Emai ID or Name"
                        name="asgEmailStep3"
                      />
                    </>
                  )}
                />
              </Paper>
            </Grid>
            {true && (
              <>
                <Grid item className={classes.grid8}>
                  <CustomTextfield
                    label="Email Subject"
                    name="subject"
                    fullWidth
                    size="small"
                    variant="outlined"
                    style={{ marginTop: 10 }}
                    onChange={stakeHolderContentChange}
                    helperText="Hint : survey ESG requires contribution"
                  />
                </Grid>
                <Grid item className={classes.grid8}>
                  <Typography className={classes.Typography5}>
                    Content
                  </Typography>
                </Grid>
                <Grid item className={classes.grid8}>
                  <CustomTextfield
                    name="content"
                    multiline
                    onChange={(e) => {
                      stakeHolderContentChange(e);
                    }}
                    className={classes.CustomTextfield}
                    defaultValue={`At ${
                      login.selectedOrganization &&
                      login.selectedOrganization.company_name
                    } we are proud to have you as one of our key stakeholder. As part of our sustainability reporting process, We are reaching out to gather your views as to the key sustainability topics we should report on.`}
                    fullWidth
                    variant="outlined"
                    inputProps={{ className: classes.shareContentText }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Grid container spacing={2} className={classes.grid7}>
            <Grid item xs={8} md={8} className={classes.grid3}>
              <CustomButton
                color="primary"
                variant="outlined"
                onClick={() => {
                  setShareDialogOpen(false);
                  setEmails([]);
                }}
              >
                Cancel
              </CustomButton>
            </Grid>
            <Grid item xs={4} md={4} className={classes.grid6}>
              <CustomButton
                color="primary"
                variant="contained"
                className={classes.CustomButton3}
                onClick={() => {
                  handleShareAssessment();
                  // setCheckedState(
                  //   new Array(generalSettings.allUsers.length).fill(false)
                  // );
                  setShareDialogOpen(false);
                }}
              >
                Send Assessment
              </CustomButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };
  //propdrilling shareAssessment states and dialog to maturityMatrixRight
  const initialShareAssessmentUtils = {
    shareDialogOpen,
    setShareDialogOpen,
    stakeholderContent,
    setStakeholderContent,
    emails,
    setEmails,
    shareDialog,
  };
  const [shareAssessmentUtils] = useState(initialShareAssessmentUtils);
  const [mainTabno, setMainTabno] = useState(0);
  const [info, setInfo] = useState(false);
  const [suggestions, setSuggestions] = useState(false);
  const [externalSuggestions, setExternalSuggestions] = useState(false);
  const [contributor, setContributor] = useState(false);
  const [details, setDetails] = useState(false);

  //to set the right side tab always at internal survery tab
  useEffect(() => {
    setMainTabno(0);
  }, [basicInfo.basicInfoTab]);

  useEffect(() => {
    if (mainTabno === 1 && basicInfo.basicInfoTab === 1) {
      getExternalUsers(login.token, assessment_id);
    } else if (mainTabno === 0 && basicInfo.basicInfoTab === 1) {
      getInternalMaturityMatrix(login.token, assessment_id);
    }
  }, [mainTabno]); // eslint-disable-line react-hooks/exhaustive-deps

  function TabPanel(props) {
    const { children, value, index } = props;
    return <div>{value === index && <Box>{children}</Box>}</div>;
  }

  useEffect(() => {
    // getInternalMaturityMatrix(token,assessment_id)
    if (mainTabno === 0) {
      setCurrentMaturityMatrixData(maturityMatrix.internalData);
    } else if (mainTabno === 1) {
      maturityMatrix.externalData &&
        setCurrentMaturityMatrixData(currentMaturityData);
    }
  }, [maturityMatrix.internalData, maturityMatrix.externalData]);
  const levelText = (score) => {
    if (score >= 0 && score <= 33) {
      return "Beginner";
    } else if (score > 33 && score <= 66) {
      return "Intermediate";
    } else if (score > 66 && score <= 100) {
      return "Expert";
    }
  };
  return (
    <>
      {shareDialogOpen && shareDialog()}
      {isSectorDialogOpen && sectorIndustry()}
      {isStakeholderDialogOpen && editStakeHolderDialog()}
      {basicInfo.basicInfoTab === 0 && <BasicInfoTabRight {...props} />}
      {basicInfo.basicInfoTab === 1 && (
        <Card
          variant="elevation"
          id="drawer-container"
          elevation={1}
          className={classes.cardContainer}
          data-tut="reactour__basicLeft"
        >
          <CardContent
            style={{ padding: "10px" }}
            className={classes.cardContent}
          >
            <>
              <MuiThemeProvider theme={theme}>
                <AppBar
                  position="static"
                  color="inherit"
                  className={classes.AppBar}
                >
                  <Tabs
                    value={mainTabno}
                    // onChange={handleTabChange}
                  >
                    <Tab
                      onClick={() => {
                        setMainTabno(0);
                        setInfo(false);
                        setDetails(false);
                        setExternalSuggestions(false);
                      }}
                      disableRipple
                      className={classes.tab}
                      label={
                        <span className={classes.tabLabel}>
                          Internal Survey
                        </span>
                      }
                    />
                    <Tab
                      disableRipple
                      onClick={() => {
                        setMainTabno(1);
                        setInfo(false);
                        setSuggestions(false);
                        setContributor(false);
                      }}
                      className={classes.tab}
                      label={
                        <span className={classes.tabLabel}>
                          External Survey
                        </span>
                      }
                    />
                  </Tabs>
                </AppBar>
              </MuiThemeProvider>
              <TabPanel value={mainTabno} index={0}>
                {/* <div className="scroll" style={{ height: '64vh' }}> */}
                {info && <Instruction />}
                {contributor && <Contributors />}
                {suggestions && <Suggestions />}
                {!info && !contributor && !suggestions && mainTabno === 0 && (
                  <MaturityMatrixRight
                    {...props}
                    setInfo={setInfo}
                    mainTabno={mainTabno}
                    setSuggestions={setSuggestions}
                    setContributor={setContributor}
                    currentMaturityData={currentMaturityData}
                    setCurrentMaturityMatrixData={setCurrentMaturityMatrixData}
                    shareAssessmentUtils={shareAssessmentUtils}
                  />
                )}
                {/* </div> */}
              </TabPanel>
              <TabPanel value={mainTabno} index={1}>
                {/* <div className="scroll" style={{ height: '64vh' }}> */}
                {info && mainTabno === 1 && <Instruction />}
                {externalSuggestions && <Suggestions />}
                {details && !externalSuggestions && mainTabno === 1 && (
                  <MaturityMatrixRight
                    setCurrentMaturityMatrixData={setCurrentMaturityMatrixData}
                    shareAssessmentUtils={shareAssessmentUtils}
                    currentMaturityData={currentMaturityData}
                    {...props}
                    mainTabno={mainTabno}
                    setInfo={setInfo}
                    setDetails={setDetails}
                    setContributor={setContributor}
                    setExternalSuggestions={setExternalSuggestions}
                  />
                )}
                {!info && !details && mainTabno === 1 && (
                  <>
                    <Grid container className={classes.mainHeadder}>
                      <Grid item>
                        <Typography className={classes.Typography}>
                          Value Chain Stakeholders
                        </Typography>
                      </Grid>
                      <Grid item className={classes.iconCont}>
                        <Tooltip
                          className={classes.cursorPointer}
                          title="Refresh"
                          placement="top"
                          arrow
                        >
                          <SyncIcon
                            onClick={() => {
                              getExternalUsers(login.token, assessment_id);
                            }}
                            className={classes.headerIcon}
                            color="primary"
                            variant="outlined"
                          />
                        </Tooltip>
                        <Tooltip
                          className={classes.cursorPointer}
                          title="Info"
                          placement="top"
                          arrow
                        >
                          <InfoOutlinedIcon
                            onClick={() => {
                              setInfo(true);
                            }}
                            className={classes.headerIcon}
                            color="primary"
                            variant="outlined"
                          />
                        </Tooltip>
                        <Tooltip
                          className={classes.cursorPointer}
                          title="Share"
                          placement="top"
                          onClick={() => setShareDialogOpen(true)}
                          arrow
                        >
                          <ShareOutlinedIcon
                            className={classes.headerIcon}
                            color="primary"
                            variant="outlined"
                          />
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <div style={{ height: "58.5vh" }}>
                      <TableContainer>
                        <Table
                          className={classes.table}
                          size="small"
                          aria-label="a dense table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell component={"td"}></TableCell>
                              <TableCell component={"td"}>
                                <Typography className={classes.tableHeaderText}>
                                  Company Name
                                </Typography>
                              </TableCell>
                              <TableCell component={"td"}>
                                <Typography className={classes.tableHeaderText}>
                                  Type
                                </Typography>
                              </TableCell>
                              <TableCell component={"td"}>
                                <Typography className={classes.tableHeaderText}>
                                  Score
                                </Typography>
                              </TableCell>
                              <TableCell component={"td"}>
                                <Typography className={classes.tableHeaderText}>
                                  Action
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {maturityMatrix?.externalData?.map(
                              (company, id) => (
                                <TableRow
                                  className={classes.tr}
                                  component={"tr"}
                                  key={id}
                                >
                                  <TableCell
                                    style={{ padding: 0 }}
                                    component="td"
                                    scope="row"
                                  >
                                    <Avatar
                                      style={{
                                        width: "35px",
                                        height: "35px",
                                      }}
                                      src={
                                        // company.profile_img
                                        //   ? company.profile_img.url :
                                        ProfilePlaceholder
                                      }
                                      alt="logo"
                                    />
                                  </TableCell>
                                  <TableCell
                                    style={{ display: "flex" }}
                                    component="td"
                                    scope="row"
                                  >
                                    <Typography
                                      style={{
                                        paddingTop: "8px",
                                        color: "#1C4066",
                                      }}
                                      className={clsx(
                                        classes.boldText,
                                        classes.color
                                      )}
                                    >
                                      {company.organisation_name}
                                    </Typography>
                                  </TableCell>
                                  <TableCell component={"td"}>
                                    <Typography className={classes.color}>
                                      {company.type}
                                    </Typography>
                                  </TableCell>
                                  <TableCell component={"td"}>
                                    <Typography className={classes.color}>
                                      {levelText(company.total_value)}
                                    </Typography>
                                  </TableCell>

                                  <TableCell
                                    component={"td"}
                                    className={classes.TableCell}
                                  >
                                    <CustomButton
                                      size="small"
                                      color="primary"
                                      onClick={() => {
                                        setDetails(true);
                                        setCurrentMaturityMatrixData(company);
                                      }}
                                    >
                                      Details
                                    </CustomButton>
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      {maturityMatrix?.externalData?.length === 0 && (
                        <Typography align="center" style={{ marginTop: 20 }}>
                          No external data found.
                        </Typography>
                      )}
                    </div>
                  </>
                )}
                {/* </div> */}
              </TabPanel>
            </>
          </CardContent>
        </Card>
      )}
    </>
  );
}
