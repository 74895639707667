import React, { useState } from "react";
import { useSelector } from "react-redux";
import { BlueBackgroundCard } from "../surveyComponents/Inner/Elements/MainTabs/ResponseComponents/DrawerSections/ResponseSectionList";
import NewsArticle from "../esgControversy/newsArticle";
import {
  AppBar,
  Icon,
  MuiThemeProvider,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { appTheme } from "../riskAssessment/brmDetaails";
import TabPanel from "../../UI/tabPannel/tabPannel";
import { appbarStyles } from "../esgCompanyProfile/esgCompantProfile";

// import Controversy from "../esgControversy/controversy";
import ControversyProfile from "../esgControversy/controversyProfile";
import Insights from "./ControversyComps/Insights";

function Contoversies(props) {
  const { esgScore, controversiesDetails, articles, ...esgInsights } =
    useSelector((state) => state?.esgDiagnosis);

  // console.log(esgScore, esgInsights);
  const [controversyTabNo, setControversyTabNo] = useState(0);
  const classes = appbarStyles();
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          {" "}
          <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
            Controversies
          </Typography>
        </div>
        <div>
          {/* <InfoOutlinedIcon
            style={{ color: "#3374b9", marginRight: "12px", cursor: "pointer" }}
          /> */}
          <Icon style={{ cursor: "pointer", color: "#3374b9", fontSize: 20 }}>
            more_vert
          </Icon>
        </div>
      </div>
      <MuiThemeProvider theme={appTheme}>
        <AppBar position="static" color="inherit" className={classes.AppBar}>
          <Tabs
            value={controversyTabNo}
            onChange={(e, newVal) => {
              setControversyTabNo(newVal);
            }}
            // onChange={handleTabChange}
          >
            <Tab
              disableRipple
              className={classes.tab}
              label={
                <span className={classes.tabLabel}>Controversy Score</span>
              }
            />
            <Tab
              disableRipple
              className={classes.tab}
              label={<span className={classes.tabLabel}>Articles </span>}
            />
            <Tab
              disableRipple
              className={classes.tab}
              label={<span className={classes.tabLabel}>Insights</span>}
            />
          </Tabs>
        </AppBar>
      </MuiThemeProvider>
      <TabPanel value={controversyTabNo} index={0}>
        <div
          className="scroll"
          style={{ height: "55vh", marginTop: "10px", paddingBottom: 20 }}
        >
          <ControversyProfile
            riskEvaluator={esgScore[0]}
            dummyControversies={controversiesDetails}
          />
        </div>
      </TabPanel>
      <TabPanel value={controversyTabNo} index={1}>
        <div
          className="scroll"
          style={{ height: "55vh", marginTop: "10px", paddingBottom: 20 }}
        >
          <BlueBackgroundCard heading={"News Articles"} />
          <NewsArticle articles={articles} {...props} />
        </div>
      </TabPanel>
      <TabPanel value={controversyTabNo} index={2}>
        <Insights />
      </TabPanel>
    </div>
  );
}

export default Contoversies;
