import {
  SET_CORPORATES,
  SET_CORPORATES_SHARE_SURVEY,
  SET_QUESTION_WITH_STATUS,
  SET_SELECTED_ASSIGN_SURVEY,
  SET_SELECTED_ADMIN_SECTION,
  SET_APPROVED_ANSWER_DATA,
  SET_AUDIT_SECTION_DETAILS,
  SET_AUDIT_SELECTED_SECTION,
  SET_AUDIT_QUESTION_DETAILS,
  SET_ESG_RATING,
  SET_SELECTED_CORPORATE,
  SET_TOTAL_CORPORATES,
} from "../../constants/supplierAdmin/supplierAdminConstant";
import { set_loader, unset_loader } from "../loader/loader_action";
import UNIVERSAL from "../../config/config";
// import {
//   getCompanyInsights,
//   getControversyData,
//   setCompanyInsights,
// } from "../riskEvaluator/riskEvaluatorActions";
import encrypt from "../shared/sharedActions";
import { set_snack_bar } from "../snackbar/snackbar_action";
import { SET_SELF_ASSESSMENT_SCORE } from "constants/supplier/esgDiagnosisConstant";

export const setCorporates = (payload) => {
  return {
    type: SET_CORPORATES,
    payload,
  };
};

export const setTotalCorporates = (payload) => {
  return {
    type: SET_TOTAL_CORPORATES,
    payload,
  };
};

export const setCorporatesShareSurvey = (payload) => {
  return {
    type: SET_CORPORATES_SHARE_SURVEY,
    payload,
  };
};

export const setSelectedAssignSurvey = (payload) => {
  return {
    type: SET_SELECTED_ASSIGN_SURVEY,
    payload,
  };
};

export const setQuestionWithStatus = (payload) => {
  return {
    type: SET_QUESTION_WITH_STATUS,
    payload,
  };
};

export const setSelectedAdminSection = (payload) => {
  return {
    type: SET_SELECTED_ADMIN_SECTION,
    payload,
  };
};

export const setApprovedAnswerData = (payload) => {
  return {
    type: SET_APPROVED_ANSWER_DATA,
    payload,
  };
};

export const setAuditSectionDetails = (payload) => {
  return {
    type: SET_AUDIT_SECTION_DETAILS,
    payload,
  };
};
export const setSelectedSectionAudit = (payload) => {
  return {
    type: SET_AUDIT_SELECTED_SECTION,
    payload,
  };
};
export const setAuditQuestionDetails = (payload) => {
  return {
    type: SET_AUDIT_QUESTION_DETAILS,
    payload,
  };
};

export const setEsgRating = (payload) => {
  return {
    type: SET_ESG_RATING,
    payload,
  };
};

export const setSelectedCorporate = (payload) => {
  // console.log(payload);
  return {
    type: SET_SELECTED_CORPORATE,
    payload,
  };
};

export function getCorporates(
  token,
  supplier_id,
  keyword = "",
  limit,
  starting_after
) {
  return (dispatch) => {
    dispatch(set_loader(true));
    const data = encrypt({
      "user-token": token,
      supplier_id,
      keyword,
      limit,
      starting_after,
    });
    return fetch(UNIVERSAL.BASEURL + "/supplier/get_corporates", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setCorporates(responseJson?.result));
          dispatch(setTotalCorporates(responseJson?.total));
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(unset_loader());
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));

          dispatch(unset_loader());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function getCorporatesShareSurveys(token, supplier_id, organization_id) {
  // console.log(organization_id)

  return (dispatch) => {
    dispatch(set_loader(true));
    const data = encrypt({
      "user-token": token,
      supplier_id,
      organization_id,
    });
    return fetch(UNIVERSAL.BASEURL + "/survey/get_shared_surveys", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setCorporatesShareSurvey(responseJson?.result));
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(unset_loader());
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));

          dispatch(unset_loader());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function getQuestionWithStatus(
  token,
  supplier_id,
  organization_id,
  section_id,
  version,
  frequency_id
) {
  return (dispatch) => {
    dispatch(set_loader(true));
    const data = encrypt({
      "user-token": token,
      supplier_id,
      organization_id,
      section_id,
      version,
      frequency_id,
    });
    return fetch(UNIVERSAL.BASEURL + "/survey/get_questions_with_status", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          // dispatch(setCorporatesShareSurvey(responseJson?.result));
          dispatch(setQuestionWithStatus(responseJson?.result));
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(unset_loader());
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));

          dispatch(unset_loader());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function assignSurveySections(
  token,
  supplier_id,
  organization_id,
  survey_id,
  section_ids,
  contributors,
  approvers,
  version,
  frequency_id,
  selectedSection
) {
  return (dispatch) => {
    dispatch(set_loader(true));
    const data = encrypt({
      "user-token": token,
      supplier_id,
      organization_id,
      survey_id,
      section_ids,
      contributors,
      approvers,
      version,
      frequency_id,
    });
    return fetch(UNIVERSAL.BASEURL + "/supplier_user/assign_survey_sections", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));

          selectedSection &&
            dispatch(
              getQuestionWithStatus(
                token,
                supplier_id,
                organization_id,
                selectedSection?._id,
                version,
                frequency_id
              )
            );
          // dispatch(setCorporatesShareSurvey(responseJson?.result));
          dispatch(unset_loader());
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));

          dispatch(unset_loader());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function assignSurveyQuestions(
  token,
  supplier_id,
  organization_id,
  survey_id,
  question_ids,
  contributors,
  approvers,
  version,
  frequency_id,
  selectedSection
) {
  return (dispatch) => {
    dispatch(set_loader(true));
    const data = encrypt({
      "user-token": token,
      supplier_id,
      organization_id,
      survey_id,
      question_ids,
      contributors,
      approvers,
      version,
      frequency_id,
      section_id: selectedSection?._id,
    });
    return fetch(UNIVERSAL.BASEURL + "/supplier_user/assign_survey_questions", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));

          selectedSection &&
            dispatch(
              getQuestionWithStatus(
                token,
                supplier_id,
                organization_id,
                selectedSection?._id,
                version,
                frequency_id
              )
            );
          // dispatch(setCorporatesShareSurvey(responseJson?.result));
          dispatch(unset_loader());
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));

          dispatch(unset_loader());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function getApprovedAnswer(
  token,
  organization_id,
  supplier_id,
  question_id,
  frequency_id,
  version
) {
  return (dispatch) => {
    dispatch(set_loader(true));
    const data = encrypt({
      "user-token": token,
      organization_id,
      supplier_id,
      question_id,
      version,
      frequency_id,
    });
    return fetch(UNIVERSAL.BASEURL + "/survey/get_approved_answer", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));

          dispatch(setApprovedAnswerData(responseJson.result));

          // dispatch(setCorporatesShareSurvey(responseJson?.result));
          dispatch(unset_loader());
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));

          dispatch(unset_loader());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function getAuditSections(
  token,
  supplier_id,
  organization_id,
  survey_id,
  version,
  frequency_id
) {
  return (dispatch) => {
    dispatch(set_loader(true));
    const data = encrypt({
      "user-token": token,
      supplier_id,
      organization_id,
      survey_id,
      version,
      frequency_id,
    });
    return fetch(UNIVERSAL.BASEURL + "/survey/get_sections_for_audit_freeze", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));

          dispatch(setAuditSectionDetails(responseJson?.result));

          // dispatch(setCorporatesShareSurvey(responseJson?.result));
          dispatch(unset_loader());
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));

          dispatch(unset_loader());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function getAuditQuestions(
  token,
  supplier_id,
  organization_id,
  section_id,
  version,
  frequency_id
) {
  return (dispatch) => {
    dispatch(set_loader(true));
    const data = encrypt({
      "user-token": token,
      supplier_id,
      organization_id,
      section_id,
      version,
      frequency_id,
    });
    return fetch(UNIVERSAL.BASEURL + "/survey/get_questions_for_audit_freeze", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));

          dispatch(setAuditQuestionDetails(responseJson?.result));

          // dispatch(setCorporatesShareSurvey(responseJson?.result));
          dispatch(unset_loader());
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));

          dispatch(unset_loader());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function updateAuditQuestionStatus(
  token,
  supplier_id,
  organization_id,
  question_id,
  frequency_id,
  status,
  survey_id,
  version
) {
  return (dispatch) => {
    dispatch(set_loader(true));
    const data = encrypt({
      "user-token": token,
      supplier_id,
      organization_id,
      question_id,
      status,
      frequency_id,
    });
    return fetch(UNIVERSAL.BASEURL + "/survey/update_question_status", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));

          dispatch(
            getAuditSections(
              token,
              supplier_id,
              organization_id,
              survey_id,
              version,
              frequency_id
            )
          );

          // dispatch(setAuditQuestionDetails(responseJson?.result))

          // dispatch(setCorporatesShareSurvey(responseJson?.result));
          dispatch(unset_loader());
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));

          dispatch(unset_loader());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function submitAdminResponse(
  token,
  supplier_id,
  organization_id,
  survey_id,
  frequency_id,
  is_default,
  version
) {
  return (dispatch) => {
    dispatch(set_loader(true));
    const data = encrypt({
      "user-token": token,
      organization_id,

      supplier_id,
      survey_id,
      frequency_id,
      is_default,
      version,
    });
    return fetch(UNIVERSAL.BASEURL + "/survey/submit_response ", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));

          // dispatch(setAuditQuestionDetails(responseJson?.result))

          // dispatch(setCorporatesShareSurvey(responseJson?.result));
          dispatch(unset_loader());
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));

          dispatch(unset_loader());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function getSupplierEsgRating(token, supplier_id, organization_id) {
  // console.log(organization_id);
  return (dispatch) => {
    dispatch(set_loader(true));
    const data = encrypt({
      "user-token": token,
      supplier_id,
      organization_id,
    });
    return fetch(UNIVERSAL.BASEURL + "/supplier/get_esg_rating", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          // dispatch(setCorporatesShareSurvey(responseJson?.result));
          // dispatch(setQuestionWithStatus(responseJson?.result));
          dispatch(setEsgRating(responseJson?.result));
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(unset_loader());
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));

          dispatch(unset_loader());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function getSupplierSelfAssessmentScore(
  token,
  supplier_id,
  organization_id
) {
  // console.log(organization_id);
  return (dispatch) => {
    dispatch(set_loader(true));
    const data = encrypt({
      "user-token": token,
      supplier_id,
      organization_id,
    });
    return fetch(UNIVERSAL.BASEURL + "/supplier/get_self_assessment_score", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          // dispatch(setCorporatesShareSurvey(responseJson?.result));
          // dispatch(setQuestionWithStatus(responseJson?.result));
          dispatch({
            type: SET_SELF_ASSESSMENT_SCORE,
            payload: responseJson?.result,
          });
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(unset_loader());
        } else {
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));

          dispatch(unset_loader());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
