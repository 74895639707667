import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Chart, Radar } from "react-chartjs-2";
import { blue, lightBlue } from "@material-ui/core/colors";
import GetAppIcon from "@material-ui/icons/GetApp";
import jsPDF from "jspdf";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

export function RadarChart(props) {
  const { chartEsgData, allData, basicInfo } = props;

  console.log();

  const defaultEnvironment = [
    "Air Quality",
    "Ecological Impacts",
    "Energy Management",
    "GHG Emissions",
    ["Waste & Hazardous", "Materials Management"],
    ["Water &", "Wastewater", "Management"],
  ];
  const defaultSocial = [
    ["Access &", "Affordability"],
    "Customer Privacy",
    "Customer Welfare",
    "Data Security",
    ["Human Rights &", "Community Relations"],
    ["Product Quality", "& Safety"],
    ["Selling Practices", "&", "Product Labeling"],
  ];
  const defaultGovernance = [
    "Business Ethics",
    "Competitive Behavior",
    ["Critical Incident", "Risk Management"],
    ["Management of the", "Legal & Regulatory", "Environment"],
    ["Systemic Risk", "Management"],
  ];
  const defaultBusiness = [
    ["Business Model", "Resilience"],
    ["Materials Sourcing", "& Efficiency"],
    ["Physical Impacts", "of", "Climate Change"],
    ["Product Design", "&", "Lifecycle Management"],
    "Supply Chain",
    "Management",
  ];
  const defaultHuman = [
    ["Employee Engagement,", "Diversity", "&", "Inclusion"],
    ["Employee Health", "& Safety"],
    "Labor Practices",
  ];
  //    const allData= {
  //     "environmentObj": {
  //         "Air Quality": {
  //             "category": "Environment",
  //             "ans": 3,
  //             "total": 5
  //         },
  //         "Ecological Impacts": {
  //             "category": "Environment",
  //             "ans": 3,
  //             "total": 5
  //         },
  //         "Energy Management": {
  //             "category": "Environment",
  //             "ans": 4,
  //             "total": 5
  //         },
  //         "GHG Emissions": {
  //             "category": "Environment",
  //             "ans": 2,
  //             "total": 5
  //         },
  //         "Waste & Hazardous Materials Management": {
  //             "category": "Environment",
  //             "ans": 3,
  //             "total": 5
  //         },
  //         "Water & Wastewater Management": {
  //             "category": "Environment",
  //             "ans": 3,
  //             "total": 5
  //         }
  //     },
  //     "socialObj": {
  //         "Access & Affordability": {
  //             "category": "Social Capital",
  //             "ans": 7,
  //             "total": 10
  //         },
  //         "Customer Privacy": {
  //             "category": "Social Capital",
  //             "ans": 3,
  //             "total": 5
  //         },
  //         "Customer Welfare": {
  //             "category": "Social Capital",
  //             "ans": 2,
  //             "total": 5
  //         },
  //         "Data Security": {
  //             "category": "Social Capital",
  //             "ans": 6,
  //             "total": 10
  //         },
  //         "Human Rights & Community Relations": {
  //             "category": "Social Capital",
  //             "ans": 7,
  //             "total": 15
  //         },
  //         "Product Quality & Safety": {
  //             "category": "Social Capital",
  //             "ans": 8,
  //             "total": 10
  //         },
  //         "Selling Practices & Product Labeling": {
  //             "category": "Social Capital",
  //             "ans": 3,
  //             "total": 5
  //         }
  //     },

  //     "governanceObj": {
  //         "Business Ethics": {
  //             "category": "Leadership & Governance",
  //             "ans": 6,
  //             "total": 10
  //         },
  //         "Competitive Behavior": {
  //             "category": "Leadership & Governance",
  //             "ans": 2,
  //             "total": 5
  //         },
  //         "Critical Incident Risk Management": {
  //             "category": "Leadership & Governance",
  //             "ans": 3,
  //             "total": 5
  //         },
  //         "Management of the Legal & Regulatory Environment": {
  //             "category": "Leadership & Governance",
  //             "ans": 3,
  //             "total": 5
  //         },
  //         "Systemic Risk Management": {
  //             "category": "Leadership & Governance",
  //             "ans": 8,
  //             "total": 15
  //         }
  //     },

  //     "businessObj": {
  //         "Business Model Resilience": {
  //             "category": "Business Model & Innovation",
  //             "ans": 3,
  //             "total": 10
  //         },
  //         "Materials Sourcing & Efficiency": {
  //             "category": "Business Model & Innovation",
  //             "ans": 10,
  //             "total": 15
  //         },
  //         "Physical Impacts of Climate Change": {
  //             "category": "Business Model & Innovation",
  //             "ans": 3,
  //             "total": 5
  //         },
  //         "Product Design & Lifecycle Management": {
  //             "category": "Business Model & Innovation",
  //             "ans": 8,
  //             "total": 10
  //         },
  //         "Supply Chain Management": {
  //             "category": "Business Model & Innovation",
  //             "ans": 4,
  //             "total": 10
  //         }
  //     },
  //     "humanObj": {
  //         "Employee Engagement, Diversity & Inclusion": {
  //             "category": "Human Capital",
  //             "ans": 6,
  //             "total": 10
  //         },
  //         "Employee Health & Safety": {
  //             "category": "Human Capital",
  //             "ans": 3,
  //             "total": 5
  //         }
  //     }
  // }

  // const reduxData= useSelector(state=>state.maturityMatrix)
  // console.log('reduxData',reduxData)
  const { environmentObj, socialObj, governanceObj, businessObj, humanObj } =
    allData;
  const { ChartTabNo } = props;
  const bgColor = {
    id: "bgColor",
    beforeDraw: (chart, options) => {
      const { ctx, width, height } = chart;
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, width, height);
      ctx.restore();
    },
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      r: {
        suggestedMin: 0,
        suggestedMax: 1,
      },
    },
    chartArea: {
      backgroundColor: "blue",
    },
    plugins: [
      {
        beforeDraw: (chart, steps, options) => {
          const { ctx, width, height } = chart;
          ctx.fillStyle = "white";
          ctx.restore();
        },
      },
    ],
  };

  const data = {
    labels: [
      "Environment",
      "Social Capital",
      "Leadership & Governance",
      "Business Model & Innovation",
      "Human Capital",
    ],
    datasets: [
      {
        label: basicInfo?.companyName,
        // data:[0.6, 0.5538461538461539, 0.6285714285714286, 0.56, 0.6],
        data: chartEsgData,
        backgroundColor: "rgba(255, 99, 00, 0.15)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };

  const config = {
    plugins: [bgColor],
  };

  const label = (dataObject) => {
    if (dataObject === undefined && ChartTabNo === 1) {
      return defaultEnvironment;
    } else if (dataObject === undefined && ChartTabNo === 2) {
      return defaultSocial;
    } else if (dataObject === undefined && ChartTabNo === 3) {
      return defaultGovernance;
    } else if (dataObject === undefined && ChartTabNo === 4) {
      return defaultBusiness;
    } else if (dataObject === undefined && ChartTabNo === 5) {
      return defaultHuman;
    } else {
      return Object.keys(dataObject !== undefined && dataObject);
    }
  };

  const labels = [
    `${basicInfo?.companyName} - Environment`,
    `${basicInfo?.companyName} - Social Capital`,
    `${basicInfo?.companyName} - Leadership`,
    `${basicInfo?.companyName} - Business Model`,
    `${basicInfo?.companyName} - Human Capital`,
  ];

  const getData = (dataObject, i) => {
    // console.log(Object.values(dataObject)[0].category);
    return {
      labels: label(dataObject),
      datasets: [
        {
          label: labels[ChartTabNo - 1],
          data:
            dataObject !== undefined &&
            Object.values(dataObject)?.map((val) => val.ans / val.total),
          backgroundColor: "rgba(0, 0, 0, 0.1)",
          borderColor: "blue",
          borderWidth: 1,
        },
      ],
    };
  };

  const ref = useRef(null);
  const refC1 = useRef(null);
  const refC2 = useRef(null);
  const refC3 = useRef(null);
  const refC4 = useRef(null);
  const refC5 = useRef(null);

  const download = useCallback((ref) => {
    const canvas = ref.current;
    const canvasImage = canvas.toBase64Image();
    let pdf = new jsPDF();
    pdf.addImage(canvasImage, "JPEG", 20, 20, 185, 150);
    pdf.save("mychart.pdf");
  }, []);

  return (
    <div style={{ padding: "10px 5px", height: "60vh" }}>
      {ChartTabNo === 0 && (
        <>
          <GetAppIcon
            style={{ float: "right" }}
            onClick={() => {
              download(ref);
            }}
          />{" "}
          <Radar ref={ref} config={config} options={options} data={data} />
        </>
      )}
      {ChartTabNo === 1 && (
        <>
          {" "}
          <GetAppIcon
            style={{ float: "right" }}
            onClick={() => {
              download(refC1);
            }}
          />{" "}
          <Radar ref={refC1} options={options} data={getData(environmentObj)} />
        </>
      )}
      {ChartTabNo === 2 && (
        <>
          {" "}
          <GetAppIcon
            style={{ float: "right" }}
            onClick={() => {
              download(refC2);
            }}
          />{" "}
          <Radar ref={refC2} options={options} data={getData(socialObj)} />
        </>
      )}
      {ChartTabNo === 3 && (
        <>
          {" "}
          <GetAppIcon
            style={{ float: "right" }}
            onClick={() => {
              download(refC3);
            }}
          />{" "}
          <Radar ref={refC3} options={options} data={getData(governanceObj)} />
        </>
      )}
      {ChartTabNo === 4 && (
        <>
          {" "}
          <GetAppIcon
            style={{ float: "right" }}
            onClick={() => {
              download(refC4);
            }}
          />{" "}
          <Radar ref={refC4} options={options} data={getData(businessObj)} />
        </>
      )}
      {ChartTabNo === 5 && (
        <>
          {" "}
          <GetAppIcon
            style={{ float: "right" }}
            onClick={() => {
              download(refC5);
            }}
          />{" "}
          <Radar ref={refC5} options={options} data={getData(humanObj)} />{" "}
        </>
      )}
      {/*  <Radar options={options} data={data2} /> */}
    </div>
  );
}

export default RadarChart;

// import React from 'react';
// import {
//   Chart as ChartJS,
//   RadialLinearScale,
//   PointElement,
//   LineElement,
//   Filler,
//   Tooltip,
//   Legend,
// } from 'chart.js';
// import { Radar } from 'react-chartjs-2';
// import { blue, lightBlue } from '@material-ui/core/colors';

// ChartJS.register(
//   RadialLinearScale,
//   PointElement,
//   LineElement,
//   Filler,
//   Tooltip,
//   Legend
// );

// export function RadarChart(props) {
//   const data = {
//     labels: ['Environment', 'social', 'Governance'],
//     datasets: [
//       {
//         label: 'Company1',
//         data:props.data1,
//         backgroundColor: 'rgba(255, 99, 00, 0.15)',
//         borderColor: 'rgba(255, 99, 132, 1)',
//         borderWidth: 1,
//       }
//     ],
//   };

//   const options = {
//     scales: {
//       r: {
//         suggestedMin: 0,
//         suggestedMax: 1,
//       },

//     },
//   };

//   const data2 = {
//     labels: [
//       'Experience',
//       'Materiality',
//       'Stakeholder Engagement',
//       'ESG Strategy',
//       'Innovation and Growth',
//       'Data Management',
//       'Supplychain Compliance',
//       'Policy and Risk Management',
//       'Incentives',
//     ],
//     datasets: [
//       {
//         label: 'Average',
//         data: props.data2,
//         backgroundColor: 'rgba(0, 0, 0, 0.1)',
//         borderColor: 'blue',
//         borderWidth: 1,
//       },
//     ],
//   };

//   return (
//     <div style={{ padding: '0px 285px' }}>
//       <Radar options={options} data={data} />
//       <Radar options={options} data={data2} />
//     </div>
//   );
// }

// export default RadarChart;
