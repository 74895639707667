import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  makeStyles,
} from "@material-ui/core";

// other imports
// import { BlueBackgroundCard } from "../../../../../../../widgets/esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
// import { Question } from "../../../../../../selfAssessment/selfAssessmentResponse";
import OptimizeInitialRender from "./OptimizeInitialRender";
import QuestionBody from "../../QuestionTabComponents/QuestionBody";
import { useDispatch } from "react-redux";
import CustomTextField from "../../../../../../../UI/textfield/textfield";
import {
  getQuestion,
  setSelectedSection,
} from "../../../../../../../../actions/supplier/supplierAction";
import NoData from "components/widgets/NoData/NoData";

import { Question as QuestionAnswer } from "components/widgets/selfAssessment/selfAssessmentResponse";

const responseStyles = makeStyles((theme) => ({
  questionCont: {
    border: "none",
    borderRadius: 5,

    width: "100%",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    padding: "16px 0px",
  },
  question: {
    fontSize: 13,
    fontWeight: 400,
    padding: "5px 6px",
    wordBreak: "break-all",
    border: "1px solid #E9E9E9",
    backgroundColor: "#FCFCFC",
    borderRadius: "6px",
    marginLeft: 5,
    width: "80%",
  },
  weightage: {
    fontSize: 13,
    fontWeight: 400,
    padding: "5px 6px",
    wordBreak: "break-all",
    color: "#3374B9",
    border: "1px solid #EBF1F8",
    backgroundColor: "#EBF1F8",
    borderRadius: "16px",
    marginLeft: 5,
    width: "18%",
    textAlign: "center",
    height: "fit-content",
  },
  userName: {
    padding: 6,
    margin: "8px 5px",
    fontSize: 13,

    borderRadius: 5,
    cursor: "pointer",
  },
}));

export function BlueBackgroundCard({
  heading,
  subHeading,
  sideHeading,
  is_premium,
  handleClosePricingDialog,
}) {
  return (
    <div
      style={{
        // height: "50px",
        width: "100%",
        backgroundColor: "#EBF1F8",
        display: "flex",
        flexDirection: sideHeading || is_premium === false ? "row" : "column",
        alignItems: is_premium === false ? "center" : "flex-start",
        marginTop: "10px",
        justifyContent: "space-between",
      }}
    >
      <Typography
        style={{
          fontSize: "14px",
          fontWeight: 500,
          color: "#15314E",
          lineHeight: "20px",
          padding: subHeading ? "15px 15px 10px 15px" : "15px",
        }}
      >
        {heading}
      </Typography>
      {subHeading && (
        <Typography
          style={{
            fontSize: "13px",
            fontWeight: 400,
            color: "#245283",
            padding: "0px 15px 15px 15px",
          }}
        >
          {subHeading}
        </Typography>
      )}
      {sideHeading && (
        <Typography
          style={{
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "20px",
            color: "#15314E",
            padding: "15px",
            marginLeft: "auto",
          }}
        >
          {subHeading || sideHeading}
        </Typography>
      )}
      {is_premium === false && (
        <Button
          variant="contained"
          style={{
            color: "#1976D2",
            backgroundColor: "white",
            marginRight: "10px",
          }}
          size="small"
          onClick={() => handleClosePricingDialog(heading)}
          disableElevation={true}
        >
          View Details
        </Button>
      )}
    </div>
  );
}

export const Question = ({
  question,
  type,
  options,
  submittedAnswer,
  hasComment,
  weightage = 0,
  hideWeightage = false,
}) => {
  const classes = responseStyles();
  return (
    <div className={classes.questionCont}>
      <div style={{ display: "flex", width: "100%" }}>
        <Typography className={classes.question}>{question}</Typography>
        {!hideWeightage && (
          <Typography className={classes.weightage}>
            {weightage} Weightage
          </Typography>
        )}
      </div>
      <div
        style={{
          width: "100%",
          padding:
            type === "Textfield" || type === "Number" || type === "Currency"
              ? "25px 10px"
              : "25px 25px",
          pointerEvents: "none",
        }}
      >
        <QuestionBody
          questionType={type}
          answers={options}
          submittedAnswer={submittedAnswer}
        />
      </div>
      {hasComment && (
        <Box
          style={{
            width: "100%",
            padding: "10px 25px",
            pointerEvents: "none",
          }}
        >
          <QuestionBody
            questionType={"Comment box"}
            // answers={
            //   handleQuestionType === "Multichoice" ? questionData?.answers : null
            // }
            // checkBoxOption={checkBoxOption}
            // setCheckBoxOption={setCheckBoxOption}
          />
        </Box>
      )}
    </div>
  );
};

export const Wrapper = ({
  users,
  children,
  hidesearch = false,
  width = "22%",
  selected,
  setSelected,
}) => {
  const dispatch = useDispatch();
  const [usersList, setUsersList] = useState([]);

  useEffect(() => {
    setUsersList(users);
  }, [users]);

  const classes = responseStyles();
  const searchUsers = (e) => {
    if (e.target.value.trim() === "") setUsersList(users);
    else {
      const filtered = users.filter((user) =>
        user.name.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setUsersList(filtered);
    }
  };
  return (
    <div style={{ display: "flex", padding: "10px 0px", width: "100%" }}>
      <div style={{ width: width, backgroundColor: "#FBFBFB" }}>
        {!hidesearch && (
          <CustomTextField
            variant="outlined"
            size="small"
            placeholder="search"
            fullwidth
            onChange={searchUsers}
          />
        )}
        <div
          className="scroll"
          style={{
            height: hidesearch ? "56vh" : "48vh",
            marginTop: 8,
            paddingBottom: 12,
          }}
        >
          {usersList.length ? (
            usersList.map((user, i) => (
              <Typography
                key={i}
                className={classes.userName}
                style={{
                  fontWeight:
                    selected === i || selected === user?.title ? 500 : 400,
                  backgroundColor:
                    (selected === i || selected === user?.title) && "#EBF1F8",
                }}
                role="button"
                onClick={() => {
                  setSelected(user?.title);
                  dispatch(setSelectedSection(user?.title));
                }}
              >
                {user?.title}
              </Typography>
            ))
          ) : (
            <Typography>No Users Found</Typography>
          )}
        </div>
      </div>
      {children}
    </div>
  );
};

export const ResponseSectionListLeft = ({
  users,
  children,
  hidesearch = false,
  width = "22%",
  selected,
  setSelected,
  handleGetQuestion,
}) => {
  const dispatch = useDispatch();
  const [usersList, setUsersList] = useState([]);

  useEffect(() => {
    setUsersList(users);
  }, [users]);

  const classes = responseStyles();
  const searchUsers = (e) => {
    if (e.target.value.trim() === "") setUsersList(users);
    else {
      const filtered = users.filter((user) =>
        user.name.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setUsersList(filtered);
    }
  };
  return (
    <div style={{ display: "flex", padding: "10px 0px", width: "100%" }}>
      <div style={{ width: width, backgroundColor: "#FBFBFB" }}>
        {!hidesearch && (
          <CustomTextField
            variant="outlined"
            size="small"
            placeholder="search"
            fullwidth
            onChange={searchUsers}
          />
        )}
        <div
          className="scroll"
          style={{
            height: hidesearch ? "56vh" : "48vh",
            marginTop: 8,
            paddingBottom: 12,
          }}
        >
          {usersList.length ? (
            usersList.map((user, i) => (
              <Typography
                key={i}
                className={classes.userName}
                style={{
                  fontWeight:
                    selected === i || selected === user?.section_name
                      ? 500
                      : 400,
                  backgroundColor:
                    (selected === i || selected === user?.section_name) &&
                    "#EBF1F8",
                }}
                role="button"
                onClick={() => {
                  setSelected(user?.section_name);
                  dispatch(setSelectedSection(user?.section_name));
                  handleGetQuestion(user, user?.section_description);
                }}
              >
                {user?.section_name}
              </Typography>
            ))
          ) : (
            <Typography>No Users Found</Typography>
          )}
        </div>
      </div>
      {children}
    </div>
  );
};

const ResponseSectionList = ({ completedQuestions }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [showNoDataMessage, setShowNoDataMessage] = useState(false);

  useEffect(() => {
    if (completedQuestions?.length === 0) {
      const timer = setTimeout(() => {
        setIsLoading(false);
        setShowNoDataMessage(true);
      }, 1000);

      return () => clearTimeout(timer); // Clean up the timer on component unmount or if the length changes
    } else {
      setIsLoading(false);
      setShowNoDataMessage(false);
    }
  }, [completedQuestions]);

  if (isLoading && completedQuestions?.length === 0) {
    return <CircularProgress />;
  }

  if (showNoDataMessage && completedQuestions?.length === 0) {
    return (
      <Box sx={{ height: "100%", width: "100%", overflowY: "scroll" }}>
        <NoData message={"No data found for selected frequency"} />
      </Box>
    );
  }

  return (
    <Box sx={{ overflow: "scroll", height: "80%", paddingBottom: "10px" }}>
      <OptimizeInitialRender chunkSize={1}>
        {completedQuestions?.map((section, idx) => (
          <div key={section?.section_id || idx}>
            <BlueBackgroundCard
              heading={section?.section_name}
              subHeading={section?.section_description}
            />
            <OptimizeInitialRender chunkSize={1}>
              {section?.questions?.map((ques, index) => (
                <QuestionAnswer
                  key={ques?.question_id || index}
                  question={ques?.question?.question}
                  type={ques?.question?.question_type}
                  weightage={ques?.question?.weightage}
                  submittedAnswer={ques?.approved_data?.answer}
                  options={ques?.question?.answers}
                  removeEditOption={true}
                />
              ))}
            </OptimizeInitialRender>
          </div>
        ))}
      </OptimizeInitialRender>
    </Box>
  );
};

export default ResponseSectionList;
